import React, { useEffect, useState } from 'react';
import { clearAlert, setAlert } from '../utils/AlertMessage';

export default function HomeForm(props) {
    const [formDate, setFormDate] = useState(new Date().toISOString().slice(0, 10)); // Ex: '2023-01-23', la date du jour sur le PC local
    const [formUser, setFormUser] = useState({});
    const [wrongDate, setWrongDate] = useState(false); // booléen qui empêche d'avancer si la date est mauvaise (cf. updateFormDate())

    const [presetData, setPresetData] = useState(undefined);

    // Hook qui va tout simplement écrire les données prédefinies dans la variable 'presetData'
    useEffect(() => {
        setPresetData(props.presetData);
    }, [props.presetData]);

    // Hook qui va reset tout les boutons et pré-remplir la date et l'utilisateur (si possible)
    useEffect(() => {
        if (presetData) {
            setFormDate(presetData.date.slice(0, 10));
            const buttons = document.getElementById('form_selectName').querySelectorAll('button');
            resetAllButtons();

            buttons.forEach((button) => {
                if (button.id === presetData.user.user_id) {
                    button.className = 'btn btn-secondary me-2 mb-2';
                    button.setAttribute('isClicked', 'clicked');
                }
            });
        } else {
            // Demande du client : Sulpice doit être mis par défaut car c'est souvent lui qui remplit la fiche
            // Julien dit : "Pas ouf si Sulpice part à la retraite !"
            const buttons = document.getElementById('form_selectName').querySelectorAll('button');
            buttons.forEach((button) => {
                if (button.value === 'A. Sulpice') {
                    button.className = 'btn btn-secondary me-2 mb-2';
                    button.setAttribute('isClicked', 'clicked');
                }
            });
        }
    }, [presetData]);

    // Vérifie que si en mode "édition", l'auteur fait bien partie de la liste des auteurs possibles
    function checkAuthorNotInUserArray() {
        if (presetData) {
            for (let i = 0; i < props.data.length; i++) {
                if (props.data[i].id === presetData.user.user_id) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    /**
     * Mise à jour de la date
     */
    function updateFormDate(event) {
        const selectedDate = new Date(event.target.value);
        const today = new Date();
        const oldDates = props.dates; // les dates des fiches déjà remplies

        // empêcher d'entrer une date dans le futur avec le clavier
        if (selectedDate > today) {
            setAlert('Vous ne pouvez pas entrer de date dans le futur !', 'warning', 'errorDateAlert');
            setWrongDate(true);
            setFormDate(event.target.value); // la date est volontairement màj avec une valeur fausse pour que l'utilisateur se rende compte de son erreur
            event.preventDefault();
        } else if (!props.presetData && oldDates.includes(selectedDate.toISOString())) {
            // si on ne modifie pas une fiche déjà existante et qu'on a choisi une mauvaise date
            setAlert(
                'Une fiche existe déjà à cette date : ' +
                    selectedDate.toLocaleDateString('fr-FR', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    }),
                'danger',
                'errorDateAlert',
            );
            setWrongDate(true);
            setFormDate(event.target.value);
            event.preventDefault();
        } else {
            clearAlert('errorDateAlert'); // reset de la div d'alert
            setWrongDate(false);
            setFormDate(event.target.value);
        }
    }

    function resetAllButtons() {
        const buttons = document.getElementById('form_selectName').querySelectorAll('button');
        buttons.forEach((button) => {
            button.className = 'btn btn-outline-secondary me-2 mb-2';
            button.setAttribute('isClicked', 'unclicked');
        });
    }

    function buttonClicked(event) {
        /* coloriage ou non des boutons */
        resetAllButtons();

        let userButton = event.target;
        userButton.className = 'btn btn-secondary me-2 mb-2';
        userButton.setAttribute('isClicked', 'clicked');

        let obj = {
            user_id: userButton.id,
            user_name: userButton.value,
        };
        setFormUser(obj);
    }

    function handleSubmit(event) {
        // Si un des boutons est 'clicked' on l'utilise comme User ayant crée la fiche
        const buttons = document.getElementById('form_selectName').querySelectorAll('button');
        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].getAttribute('isClicked') === 'clicked') {
                let obj = {
                    user_id: buttons[i].id,
                    user_name: buttons[i].value,
                };

                props.handleHomeFormSubmit({
                    formDate: formDate,
                    formUser: obj,
                });
                props.handleCarouselRedirection(false);
                props.handlePositionCarousel(1);

                return;
            }
        }

        // Sinon on utilise la dernière valeur écrite dans 'formUser'
        props.handleHomeFormSubmit({
            formDate: formDate,
            formUser: formUser,
        });
    }

    // Affichage du formulaire de présence
    // le tableau est à afficher dynamiquement avec les membres du personnel

    return (
        <div className="container h-100">
            <div className="d-flex h-100 justify-content-center align-items-center">
                <form className="form-floating text-center w-100 m-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                    <h1 className="mb-5">{presetData ? "Édition d'une fiche" : "Création d'une nouvelle fiche"}</h1>

                    <div className="w-100 mb-3">
                        <div id="errorDateAlert"></div>
                        <label htmlFor="todayDate">Date de la fiche</label>
                        <input
                            id="form_date"
                            type="date"
                            name="todayDate"
                            className="form-control text-center"
                            value={formDate}
                            onChange={updateFormDate}
                            max={new Date().toISOString().slice(0, 10)}
                            required
                        />
                    </div>

                    <div className="form-floating mb-3">
                        {/* remplacement du select par des boutons */}
                        <div id="form_selectName">
                            <label htmlFor="floatingSelectName" className="fw-bold text-center me-4">
                                Choisissez votre nom :
                            </label>
                            {props.data
                                .filter((user) => user.mode === 2 && user.isArchived === false)
                                .map((user) => {
                                    return (
                                        <button
                                            className="btn btn-outline-secondary me-2 mb-2"
                                            type="button"
                                            id={user.id}
                                            key={user.id}
                                            value={user.fullName}
                                            onClick={buttonClicked}
                                        >
                                            {user.fullName}
                                        </button>
                                    );
                                })}
                            {checkAuthorNotInUserArray() && (
                                <button
                                    className="btn btn-outline-secondary me-2 mb-2"
                                    type="button"
                                    id={presetData.user.user_id}
                                    key={presetData.user.user_id}
                                    value={presetData.user.user_name}
                                    onClick={buttonClicked}
                                >
                                    {presetData.user.user_name}
                                </button>
                            )}
                        </div>
                    </div>

                    {/* On passe le handleSubmit ici, vu que si on le mets dans le '<form>' c'est écrasé par le carousel */}
                    <input
                        onClick={handleSubmit}
                        type="submit"
                        value="Suivant"
                        className={wrongDate ? 'btn btn-outline-danger mt-3' : 'btn btn-primary mt-3'}
                        disabled={wrongDate}
                        title={wrongDate ? "La date renseignée n'est pas correcte" : 'Suivant'}
                        style={{ cursor: wrongDate ? 'not-allowed' : 'pointer' }}
                        data-bs-target="#carouselHome"
                        data-bs-slide="next"
                    />
                </form>
            </div>
        </div>
    );
}
