import React from 'react';
import Header from '../components/header';

export default function Statistiques() {
    return (
        <>
            <div>
                <Header />
                Statistiques
            </div>
        </>
    );
}
