import React, { useState, useEffect } from 'react';
import { RequestDefaultErrorHandler, SendPOSTRequest, DownloadFile } from '../utils/FetchUtil';
import { clearAlert, setAlert } from '../utils/AlertMessage';
import { convertHourGood } from './attendanceForm';

import { BsArrowLeftCircleFill, BsExclamationTriangleFill } from 'react-icons/bs';
import { CiExport } from 'react-icons/ci';
import { BsFillPersonLinesFill, BsFillCaretDownFill } from 'react-icons/bs';

import Dropdown from 'react-bootstrap/Dropdown';

export default function RecapElem(props) {
    const [stale, setStale] = useState(false);
    useEffect(() => {}, [stale]);

    props.sendRecapUpdater(setStale);

    function handleSubmit(event) {
        event.preventDefault();

        let data_body = props.getFormData();
        if (props.presetData) {
            data_body.original_id = props.constSheetID;
        }
        SendPOSTRequest(
            props.presetData ? '/api/data/update_recap' : 'api/data/send_recap',
            data_body,
            (res, data) => {
                if (res.status === 200) {
                    alert(props.presetData ? "Succès de l'édition de la fiche" : "Succès de l'envoi de la fiche");
                    window.location.replace('/home');
                } else {
                    //alert('Echec de la requête.. Statut HTTP -> ' + data.error);
                    /*let element = document.getElementById('errorAlert');
                    element.innerHTML = `
                    <div class="alert alert-warning alert-dismissible fade show fw-bold" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>
                         <span> Une fiche a déjà été envoyée à cette date.</span>
                         <br>
                         <input type="button" value="Aller à la date" class="btn btn-warning mt-3" data-bs-target="#carouselHome" data-bs-slide-to="0"</input>
                    </div>
                    `;*/
                    setAlert('Echec de la requête.. Statut HTTP -> ' + data.error, 'danger', 'errorAlert');
                    window.scrollTo(0, 0);
                }
            },
            RequestDefaultErrorHandler,
        );
    }

    function handleBack(event) {
        props.handlePositionCarousel(2);
        event.preventDefault();
    }

    // Affichage du Recapitulatif avant validation de la fiche
    // le tableau est à afficher dynamiquement avec les membres du personnel

    let good_keys = [];
    let good_people = {};
    const formData = props.getFormData();

    /* contrôle des heures */
    let sommeHeuresActivites = 0;
    formData.chantiers.forEach((chantier) => {
        if (chantier.activites) {
            chantier.activites.forEach((act) => {
                sommeHeuresActivites += convertHourGood(act.nbHeure);
            });
        }
    });
    formData.lst402.forEach((ent402) => {
        Object.values(ent402).forEach((ent) => {
            sommeHeuresActivites += convertHourGood(ent.nbHeures);
        });
    });

    sommeHeuresActivites = Math.round(sommeHeuresActivites * 100) / 100;

    async function exportAs(type) {
        const id = props.constSheetID;
        const { requestResult, data, err } = await DownloadFile(`/api/data/export/${id}/${type}`);

        if (requestResult.status !== 200 || err) {
            // Afficher erreur
        } else {
            var url = window.URL || window.webkitURL;
            var link = url.createObjectURL(data);
            var a = document.createElement('a');
            a.setAttribute('download', 'export.' + type);
            a.setAttribute('href', link);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    /* fin contrôle des heures */
    return (
        <>
            <div className="container h-100">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <div className="form-floating text-center w-100 m-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                        <div className="d-flex form-check justify-content-center">
                            {props.isConsulting || (
                                <button
                                    value="Retour"
                                    className="mt-3 h-25 border-0 bg-transparent"
                                    data-bs-target="#carouselHome"
                                    data-bs-slide="prev"
                                    onClick={handleBack}
                                    title="Retour en arrière"
                                >
                                    <BsArrowLeftCircleFill size={29} className="text-danger" />
                                </button>
                            )}
                            <div className="d-flex form-check justify-content-center">
                                <h1 className="mb-0 ms-0 me-5">
                                    Récapitulatif de la fiche {props.isConsulting || (props.presetData && '(édition)')}
                                </h1>
                            </div>
                        </div>
                        {props.isConsulting && (
                            <Dropdown className="ms-3">
                                <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
                                    Exporter la fiche
                                    <CiExport size={25} className="ms-2" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            exportAs('csv');
                                        }}
                                        href="#"
                                    >
                                        Exporter vers <strong>CSV</strong>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        onClick={() => {
                                            exportAs('xlsx');
                                        }}
                                        href="#"
                                    >
                                        Exporter vers <strong>XLSX</strong>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        <br />
                        {/* ---------------- Div pour les erreurs -------------*/}
                        <div
                            id="errorAlert"
                            onClick={() => {
                                clearAlert('errorAlert');
                                props.handlePositionCarousel(0);
                            }}
                        ></div>
                        {/* -------------------- Info Fiche ------------------ */}
                        <div className="row rounded-3 justify-content-between align-items-center custom-line bg-primary bg-opacity-75 py-1">
                            <div className="col-4 text-start">
                                <h3 className="text-light ps-3 mb-0">
                                    {formData.date === undefined
                                        ? 'Non renseigné'
                                        : new Date(formData.date).toLocaleDateString('fr-FR', {
                                              year: 'numeric',
                                              month: 'numeric',
                                              day: 'numeric',
                                          })}
                                </h3>
                            </div>
                            <div className="col-4 text-end">
                                <h4 className="text-light pe-3 mb-0">{formData.user.user_name}</h4>
                            </div>
                        </div>
                        <br />

                        {/* -------------------- Data Présence------------------ */}
                        {Object.keys(formData.attendance).forEach((k) => {
                            if (k.includes('TOTAL') && formData.attendance[k] !== 0) {
                                good_keys.push(k.replace('TOTAL', ''));
                            }
                        })}
                        {good_keys.forEach((k) => {
                            Object.keys(formData.attendance[k]).forEach((user_id) => {
                                if (good_people[user_id] === undefined) {
                                    good_people[user_id] = {};
                                }
                                good_people[user_id][k] = formData.attendance[k][user_id];
                            });
                        })}
                        {Object.keys(good_people).forEach((k) => {
                            Object.keys(good_people[k]).forEach((cat) => {
                                if (good_people[k][cat].fullName) {
                                    good_people[k].fullName = good_people[k][cat].fullName;
                                }
                            });
                        })}
                        {/* -------------------- Show Présence ------------------ */}
                        <div className="col-md-12 column table-responsive">
                            <table className="table table-bordered table-sm p-5 table-striped">
                                <thead className="mb-0 bg-primary bg-opacity-75 py-1 px-3">
                                    <tr>
                                        <th className="text-light fw-bold h5" colSpan="100%">
                                            Présence
                                        </th>
                                    </tr>
                                </thead>
                                <thead className="bg-secondary bg-opacity-75">
                                    <tr>
                                        <th scope="col">Nom</th>
                                        {good_keys.map((k) => {
                                            if (!k.includes('TOTAL')) {
                                                return (
                                                    <th scope="col" key={k}>
                                                        {k}
                                                    </th>
                                                );
                                            } else {
                                                return false;
                                            }
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(good_people).map((user_id, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td key={user_id}>
                                                    {good_people[user_id].fullName}
                                                    {!props.data.persons.find(
                                                        (user) => !user.isArchived && user.id === user_id,
                                                    ) && (
                                                        <BsFillPersonLinesFill
                                                            size={15}
                                                            className="text-success ms-2"
                                                        />
                                                    )}
                                                </td>
                                                {good_keys.map((cat) => {
                                                    return (
                                                        <td key={cat}>
                                                            {(good_people[user_id][cat] &&
                                                                convertHourGood(good_people[user_id][cat].nbHeure) + ' h') ||
                                                                '∅'}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex w-50">
                            <div className="col-md-12 column table-responsive pe-1">
                                <table className="table tabel-sm table-bordered table-hover table-striped align-middle">
                                    <thead>
                                        <tr>
                                            <th
                                                colSpan="100%"
                                                className="bg-primary bg-opacity-75 text-light fw-bold h5"
                                            >
                                                Durée de travail totale
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>Travail total (900 et 902):</strong>
                                            </td>
                                            <td>
                                                {formData.attendance.TravailTOTAL + formData.attendance.StagiaireTOTAL}{' '}
                                                h
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Activités</strong>
                                            </td>
                                            <td>{sommeHeuresActivites} h</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {sommeHeuresActivites !==
                                formData.attendance.TravailTOTAL + formData.attendance.StagiaireTOTAL ? (
                                    <div colSpan="100%" className="alert alert-danger fw-bold ">
                                        <BsExclamationTriangleFill size={25} className="me-2 text-danger" />
                                        Incohérence : travail total / activités
                                        <br />
                                        {!props.isConsulting && (
                                            <a
                                                href=" "
                                                data-bs-target="#carouselHome"
                                                data-bs-slide-to="2"
                                                onClick={() => props.handlePositionCarousel(2)}
                                                className="alert-link"
                                            >
                                                Retour à l'erreur
                                            </a>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-md-12 column table-responsive ps-1">
                                <table className="table tabel-sm table-bordered table-hover table-striped align-middle">
                                    <thead>
                                        <tr>
                                            <th className="bg-primary bg-opacity-75 text-light fw-bold h5">
                                                Véhicules & Matériels
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.vehicules.map((vehicule, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td className="m-0">{vehicule.information}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* -------------------- Chantiers ------------------ */}
                        <div className="text-center bg-primary bg-opacity-75 py-1">
                            <p className="text-light fw-bold h4">Chantiers</p>
                        </div>
                        {formData.chantiers.map((chantier, chantierIdx) => {
                            return (
                                <div className="card m-3 bg-light" key={chantierIdx}>
                                    <div
                                        className="card-body"
                                        data-bs-toggle="collapse"
                                        title="Cliquer pour dérouler"
                                        style={{ cursor: 'pointer' }}
                                        href={'#collapseChantier' + chantierIdx}
                                    >
                                        <h5 className="card-title" key={chantier.id}>
                                            Chantier n°{chantierIdx + 1} <BsFillCaretDownFill />
                                        </h5>
                                        <div className="collapse" id={'collapseChantier' + chantierIdx}>
                                            <p className="card-text">
                                                Voici la liste des activités pour le chantier n° {chantierIdx + 1}
                                            </p>
                                            <div className="col-md-12 column table-responsive">
                                                <table className="table table-sm table-bordered p-5 table-striped align-middle">
                                                    <thead>
                                                        <tr className="bg-secondary bg-opacity-75">
                                                            <th>Commune</th>
                                                            <th>Rivière</th>
                                                            <th>Tronçon</th>
                                                            <th>Activité n°</th>
                                                            <th>Activité</th>
                                                            <th>Durée (en heures)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {chantier.activites.map((activite, activiteIdx) => {
                                                            if (activite.activite) {
                                                                return (
                                                                    <tr
                                                                        key={`${chantierIdx}-${activiteIdx}`}
                                                                        className={chantierIdx % 2 === 0 ? '' : ''}
                                                                    >
                                                                        <td>{chantier.commune.information}</td>
                                                                        <td>{chantier.riviere.information}</td>
                                                                        {chantier.troncon ? (
                                                                            <td>{chantier.troncon.information}</td>
                                                                        ) : (
                                                                            <td>{'Ø'}</td>
                                                                        )}
                                                                        <td>{activiteIdx + 1}</td>
                                                                        <td>{activite.activite.information}</td>
                                                                        <td>{convertHourGood(activite.nbHeure)}</td>
                                                                    </tr>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {/* -------------------- Chantiers 402 ------------------ */}
                        {formData.lst402.length > 0 && (
                            <div className="text-center bg-primary bg-opacity-75 py-1">
                                <p className="text-light fw-bold h4">Chantiers 402</p>
                            </div>
                        )}
                        {formData.lst402.map((lst402, lst402Idx) => {
                            return (
                                <div className="card m-3 bg-light" key={lst402Idx}>
                                    <div
                                        className="card-body"
                                        data-bs-toggle="collapse"
                                        title="Cliquer pour dérouler"
                                        style={{ cursor: 'pointer' }}
                                        href={'#collapseList402' + lst402Idx}
                                    >
                                        <h5 className="card-title" key={lst402.id}>
                                            Chantier 402 n°{lst402Idx + 1} <BsFillCaretDownFill />
                                        </h5>
                                        <div className="collapse" id={'collapseList402' + lst402Idx}>
                                            <p className="card-text">
                                                Voici le résumé du formulaire 402 n° {lst402Idx + 1}
                                            </p>
                                            <div className="col-md-12 column table-responsive">
                                                <table className="table table-sm table-bordered p-5 table-striped align-middle">
                                                    <thead>
                                                        <tr className="bg-secondary bg-opacity-75">
                                                            <th>Commune</th>
                                                            <th>Rivière</th>
                                                            <th>Tronçon</th>
                                                            <th>Durée (en heures)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.values(lst402).map((ent, idxSub) => {
                                                            return (
                                                                <tr key={idxSub}>
                                                                    <td>{ent.location.commune}</td>
                                                                    <td>{ent.location.riviere}</td>
                                                                    <td>
                                                                        {ent.location.grille.map((grille, idx2) => {
                                                                            return (
                                                                                <span key={idx2}>
                                                                                    {grille}{' '}
                                                                                    {idx2 !==
                                                                                        ent.location.grille.length -
                                                                                            1 && <br />}
                                                                                </span>
                                                                            );
                                                                        })}
                                                                    </td>
                                                                    <td>{convertHourGood(ent.nbHeures)}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {/* -------------------- Bouton valider ------------------ */}
                        {props.isConsulting || (
                            <input
                                disabled={
                                    convertHourGood(sommeHeuresActivites) !==
                                    convertHourGood(formData.attendance.TravailTOTAL) + convertHourGood(formData.attendance.StagiaireTOTAL)
                                }
                                type="submit"
                                style={
                                    convertHourGood(sommeHeuresActivites) !==
                                    convertHourGood(formData.attendance.TravailTOTAL) + convertHourGood(formData.attendance.StagiaireTOTAL)
                                        ? { cursor: 'not-allowed' }
                                        : { cursor: 'pointer' }
                                }
                                onClick={handleSubmit}
                                value={props.presetData ? 'Appliquer les modifications' : 'Valider'}
                                className="btn btn-success mt-3"
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
