import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import ResearchForm from '../components/researchForm';
import ResearchResults from '../components/researchResults';
import handleExpiredSession from '../utils/Session';
import { SendGETRequest } from '../utils/FetchUtil';

export default function Recherche(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [apiInformationData, setApiInformationData] = useState({});
    const [apiInformationError, setApiInformationError] = useState(undefined);
    const [fichesResult, setFichesResult] = useState([]);
    const userMode = localStorage.getItem('userMode');

    function receiveDataForResearchForm(data) {
        if (parseInt(userMode) === 2) {
            // mode saisie doit afficher que les fiches non validées
            let data_non_valide = []; // fiches non validées
            for (let i = 0; i < data.length; i++) {
                if (data[i].etat === 3) {
                    data_non_valide.push(data[i]);
                }
            }
            data = data_non_valide;
        }
        setFichesResult(data);
    }

    function hasExpiredError() {
        return !isLoading && apiInformationData && apiInformationError && apiInformationData.tokenExpired;
    }

    function hasOtherError() {
        return !isLoading && apiInformationError;
    }

    useEffect(() => {
        SendGETRequest('/api/data/get_all_information', setApiInformationData, setApiInformationError, setIsLoading);
    }, []);

    useEffect(() => {
        if (!isLoading && !apiInformationError && props.presetData) {
            // Pour chaque véhicule des données de pré-remplissage
            props.presetData.vehicules.forEach((veh) => {
                // Si il n'est pas dans la liste reçue du serveur, on l'ajoute dans les données
                if (!apiInformationData.vehicules.find((v) => v.code === veh.code)) {
                    apiInformationData.vehicules.push(veh);
                }
            });

            // Pour chaque chantier des données de pré-remplissage
            props.presetData.chantiers.forEach((cha) => {
                // Si sa commune n'est pas dans la liste reçue du serveur, on l'ajoute dans les données
                if (!apiInformationData.communes.find((c) => c.code === cha.commune.code)) {
                    apiInformationData.communes.push(cha.commune);
                }

                // Si sa rivière n'est pas dans la liste reçue du serveur, on l'ajoute dans les données
                if (!apiInformationData.rivieres.find((r) => r.code === cha.riviere.code)) {
                    apiInformationData.rivieres.push(cha.riviere);
                }

                // Pour chaque activité du chantier (des données de pré-remplissage)
                cha.activites.forEach((act) => {
                    // Si ce n'est pas dans la liste reçue du serveur, on l'ajoute dans les données
                    if (!apiInformationData.activites.find((a) => a.code === act.code)) {
                        apiInformationData.activites.push(act);
                    }
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <>
            <Header />
            <main>
                {isLoading && (
                    <div className="position-fixed p-2 w-100">
                        <div className="alert alert-primary" role="alert">
                            Chargement des informations...
                            <br />
                            <div className="spinner-grow text-primary m-3" role="status">
                                <span className="visually-hidden">Chargement...</span>
                            </div>
                            <div className="spinner-grow text-light m-3" role="status">
                                <span className="visually-hidden">Chargement...</span>
                            </div>
                            <div className="spinner-grow text-danger m-3" role="status">
                                <span className="visually-hidden">Chargement...</span>
                            </div>
                        </div>
                    </div>
                )}

                {!isLoading && !apiInformationError && (
                    <>
                        <ResearchForm data={apiInformationData} sendDataToParent={receiveDataForResearchForm} />
                        {fichesResult.length > 0 && <ResearchResults filteredData={fichesResult} />}
                    </>
                )}
                {(hasExpiredError() && handleExpiredSession()) ||
                    (hasOtherError() && (
                        <div
                            className="alert alert-danger mt-5 ms-5 me-5"
                            role="alert"
                            onClick={(_) => {
                                window.location.reload();
                            }}
                        >
                            <h4 className="alert-heading">Erreur!</h4>
                            <p>
                                Il semblerait qu'il y ait eu une erreur lors de la récupération des données sur le
                                serveur. Si cela continue après le rafraîchissement de la page, essayez de vous
                                déconnecter et de vous reconnecter.
                            </p>
                            <p>Si cela ne fonctionne toujours pas, contactez votre administrateur réseau..</p>
                            <p>
                                <strong>Status HTTP: {apiInformationError.error}</strong>
                            </p>
                            <hr />
                            <p className="mb-0">Cliquez sur l'encadré pour rafraîchir la page.</p>
                        </div>
                    ))}
            </main>
        </>
    );
}
