import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import handleExpiredSession from '../utils/Session';
import HomePage from '../pages/homePage';
import { SendPOSTRequest } from '../utils/FetchUtil';

export default function EditPage() {
    const constSheetID = useParams().id;

    const [isLoading, setIsLoading] = useState(true);
    const [apiSheetData, setApiSheetData] = useState({});
    const [apiSheetError, setApiSheetError] = useState(undefined);

    function hasExpiredError() {
        return !isLoading && apiSheetData && apiSheetError && apiSheetData.tokenExpired;
    }

    function hasOtherError() {
        return !isLoading && apiSheetError;
    }

    useEffect(() => {
        SendPOSTRequest(
            '/api/data/get_sheet_by_id',
            { id: constSheetID },
            (res, data) => {
                setApiSheetData(data);
                if (res.status !== 200) {
                    setApiSheetError(data);
                }
                setIsLoading(false);
            },
            (err) => {
                setApiSheetError(err);
                setIsLoading(false);
            },
        );
    }, [constSheetID]);

    return (
        <div>
            {isLoading && (
                <div className="position-fixed p-2 w-100">
                    <div className="alert alert-primary" role="alert">
                        Chargement de la fiche...
                        <br />
                        <div className="spinner-grow text-primary m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                        <div className="spinner-grow text-light m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                        <div className="spinner-grow text-danger m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && !apiSheetError && (
                // transmission des infos utilisateur au composant teamManagement.jsx
                <HomePage presetData={apiSheetData} constSheetID={constSheetID} />
            )}

            {(hasExpiredError() && handleExpiredSession()) ||
                (hasOtherError() && (
                    <div
                        className="alert alert-danger mt-5 ms-5 me-5"
                        role="alert"
                        onClick={(_) => {
                            window.location.reload();
                        }}
                    >
                        <h4 className="alert-heading">Erreur!</h4>
                        <p>
                            Il semblerait qu'il y ait eu une erreur lors de la récupération des données sur le serveur.
                            Si cela continue après le rafraîchissement de la page, essayez de vous déconnecter et de
                            vous reconnecter.
                        </p>
                        <p>Si cela ne fonctionne toujours pas, contactez votre administrateur réseau..</p>
                        <p>
                            <strong>Description: </strong> {apiSheetError.error}
                        </p>
                        <hr />
                        <p className="mb-0">Cliquez sur l'encadré pour rafraîchir la page.</p>
                    </div>
                ))}
        </div>
    );
}
