import React from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { ProSidebarProvider } from 'react-pro-sidebar';

import {
    BsFileSpreadsheet,
    BsCardChecklist,
    BsHourglassSplit,
    BsConeStriped,
    BsFillPersonFill,
    BsLink45Deg,
    BsLock,
} from 'react-icons/bs';

export default function SideMenu(props) {
    let myHeight = window.outerHeight;

    return (
        <>
            <ProSidebarProvider>
                <Sidebar style={{ backgroundColor: 'gray', borderRight: '1px solid gray' }}>
                    <Menu style={{ minHeight: myHeight }}>
                        <br />
                        <h3 className="text-center">Menu</h3>
                        <hr />
                        <MenuItem
                            onClick={(_) => {
                                props.setCurrentMenu(1);
                            }}
                        >
                            <div>
                                <i className="me-3">
                                    <BsCardChecklist size={20} />
                                </i>
                                <span>Fiches</span>
                                <span className="ms-3 position-absolute top-10 start-90 translate-middle badge rounded-pill bg-danger">
                                    {props.numWaitingSheets} <span className="visually-hidden">unread messages</span>
                                </span>
                            </div>
                        </MenuItem>
                        <hr />
                        <MenuItem
                            onClick={(_) => {
                                props.setCurrentMenu(2);
                            }}
                        >
                            <div>
                                <i className="me-3">
                                    <BsFillPersonFill size={20} />
                                </i>
                                <span>Membres</span>
                            </div>
                        </MenuItem>
                        <hr />
                        <MenuItem
                            onClick={(_) => {
                                props.setCurrentMenu(3);
                            }}
                        >
                            <div>
                                <i className="me-3">
                                    <BsFileSpreadsheet size={20} />
                                </i>
                                <span>Tables</span>
                            </div>
                        </MenuItem>
                        <hr />
                        <MenuItem
                            onClick={(_) => {
                                props.setCurrentMenu(4);
                            }}
                        >
                            <div>
                                <i className="me-3">
                                    <BsHourglassSplit size={20} />
                                </i>
                                <span>Heures</span>
                            </div>
                        </MenuItem>
                        <hr />
                        <MenuItem
                            onClick={(_) => {
                                props.setCurrentMenu(5);
                            }}
                        >
                            <div>
                                <i className="me-3">
                                    <BsConeStriped size={20} />
                                </i>
                                <span>Editeur 402</span>
                            </div>
                        </MenuItem>
                        <hr />
                        <MenuItem
                            onClick={(_) => {
                                props.setCurrentMenu(6);
                            }}
                        >
                            <div>
                                <i className="me-3">
                                    <BsLink45Deg size={20} />
                                </i>
                                <span>Editeur de liens</span>
                            </div>
                        </MenuItem>
                        <hr />
                        <MenuItem
                            onClick={(_) => {
                                props.setCurrentMenu(7);
                            }}
                        >
                            <div>
                                <i className="me-3">
                                    <BsLock size={20} />
                                </i>
                                <span>Sécurité</span>
                            </div>
                        </MenuItem>
                    </Menu>
                </Sidebar>
            </ProSidebarProvider>
        </>
    );
}
