import React, { useState, useEffect } from 'react';
import { RequestDefaultErrorHandler, SendPOSTRequest } from '../utils/FetchUtil';

import { BsArchive, BsArchiveFill, BsTrash3, BsFillSignStopFill } from 'react-icons/bs';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';

function TableElem(props) {
    const [entryCode, setEntryCode] = useState(undefined);
    const [entryInfo, setEntryInfo] = useState('');
    const [rangeValues, setRangeValues] = useState(Array(props.data.length)); // tableau des valeurs des input range

    // tri par statut d'archivage
    props.data.sort((a, b) => {
        if (a.isArchived && b.isArchived === false) {
            return 1;
        }
        if (a.isArchived === false && b.isArchived) {
            return -1;
        }
        return 0;
    });

    function handleTableSubmit(event) {
        event.preventDefault();
        SendPOSTRequest(
            '/api/data/add_table_entry',
            {
                code: parseInt(entryCode),
                information: entryInfo,
                table: props.database_name,
            },
            (res, data) => {
                if (res.status === 200) {
                    alert("Succès de l'ajout de l'élément");
                    window.location.reload();
                    window.scrollTo(0, 0);
                } else {
                    alert('Echec de la requête.. Statut HTTP ->' + res.status.toString());
                }
            },
            RequestDefaultErrorHandler,
        );
    }

    function handleTableDelete(id, name) {
        return (event) => {
            if (window.confirm('Voulez-vous vraiment supprimer ' + name + ' ?')) {
                event.preventDefault();
                SendPOSTRequest(
                    '/api/data/delete_table_entry',
                    { id: id, table: props.database_name },
                    (res, data) => {
                        if (res.status === 200) {
                            alert("Succès de la suppression de l'élément");
                            window.location.reload();
                            window.scrollTo(0, 0);
                        } else {
                            alert('Echec de la requête.. Statut HTTP -> ' + res.status.toString());
                        }
                    },
                    RequestDefaultErrorHandler,
                );
            }
        };
    }

    function handleArchiveTableSwap(id) {
        return (event) => {
            event.preventDefault();
            SendPOSTRequest(
                '/api/data/change_archive_table_status',
                { id: id, table: props.database_name },
                (res, data) => {
                    if (res.status === 200) {
                        //alert("L'élément " + (data.isArchived ? 'a bien été' : "n'est plus") + ' archivé.');
                        window.location.reload();
                        window.scrollTo(0, 0);
                    } else {
                        alert('Echec de la requête.. Statut HTTP -> ' + res.status.toString());
                    }
                },
                RequestDefaultErrorHandler,
            );
        };
    }

    function handlePriorityChange(id, valeur) {
        return (event) => {
            event.preventDefault();
            valeur = valeur === undefined ? 0 : valeur;
            SendPOSTRequest(
                '/api/data/change_vehicle_priority',
                { id: id, table: props.database_name, valeur: valeur },
                (res, data) => {
                    if (res.status === 200) {
                        window.location.reload();
                        window.scrollTo(0, 0);
                    } else {
                        alert('Echec de la requête.. Statut HTTP -> ' + res.status.toString());
                    }
                },
                RequestDefaultErrorHandler,
            );
        };
    }

    // appelée lorsqu'une input type range change de valeur
    function handleRangeChange(index, newValue) {
        setRangeValues((prevValues) => {
            const newValues = [...prevValues];
            newValues[index] = newValue;
            return newValues;
        });
    }

    return (
        <div className="container h-100">
            <div className="d-flex-column h-100 justify-content-center align-items-center">
                <div className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                    <div className="d-flex form-check justify-content-center">
                        <h1 className="mb-5 ms-5 me-5">
                            Ajouter un{props.genre === 'feminin' ? 'e' : ''} {props.displayName.toLowerCase()}
                        </h1>
                    </div>

                    <form>
                        <div className="row">
                            <div className="col-md-2 mb-3">
                                <div className="form-floating">
                                    <input
                                        required
                                        type="number"
                                        id="activityCode"
                                        className="form-control"
                                        placeholder="Code"
                                        defaultValue={entryCode}
                                        onChange={(event) => setEntryCode(event.target.value)}
                                    />
                                    <label className="form-label" htmlFor="activityCode">
                                        Code
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-10 mb-3">
                                <div className="form-floating">
                                    <input
                                        required
                                        type="text"
                                        id="activityDescription"
                                        className="form-control"
                                        placeholder="Description"
                                        defaultValue={entryInfo}
                                        onChange={(event) => setEntryInfo(event.target.value)}
                                    />
                                    <label className="form-label" htmlFor="activityDescription">
                                        Description
                                    </label>
                                </div>
                            </div>
                        </div>

                        <input
                            onClick={handleTableSubmit}
                            className="btn btn-success mt-3"
                            type="submit"
                            value="Ajouter"
                        />
                    </form>
                </div>

                <div className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                    <div className="d-flex form-check justify-content-center">
                        <h1 className="mb-5 ms-5 me-5">Table des {props.displayName.toLowerCase()}s</h1>
                    </div>
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-md-12 column table-responsive">
                                <table className="table table-sm table-bordered table-hover table-light table-striped">
                                    <thead>
                                        <tr className="table-secondary">
                                            <th scope="col">Code</th>
                                            <th scope="col">{props.displayName}</th>
                                            {/*Ajout de la colonne priorité sur les véhicules*/}
                                            {props.priority === true && <th scope="col">Priorité</th>}
                                            <th scope="col">Archivage</th>
                                            <th scope="col">Suppression</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.data.map((element, index) => (
                                            <tr key={index}>
                                                <td>{element.code}</td>
                                                <td>{element.information}</td>
                                                {/*Ajout de la colonne priorité sur les véhicules*/}
                                                {props.priority === true && (
                                                    <td>
                                                        {!element.isArchived && (
                                                            <>
                                                                <button
                                                                    value="decrement"
                                                                    className="h-25 border-0 bg-transparent"
                                                                    title={
                                                                        'Décrémenter la priorité de ' +
                                                                        element.information
                                                                    }
                                                                    disabled={element.isArchived}
                                                                    onClick={handlePriorityChange(
                                                                        element.id,
                                                                        element.priority - 1,
                                                                    )}
                                                                >
                                                                    {!element.isArchived ? (
                                                                        <AiOutlineArrowDown className="text-danger" />
                                                                    ) : (
                                                                        <BsFillSignStopFill className="text-danger" />
                                                                    )}
                                                                </button>
                                                                <span className="fw-bold">
                                                                    {rangeValues[index] === undefined
                                                                        ? element.priority
                                                                        : rangeValues[index]}
                                                                </span>
                                                                <button
                                                                    value="increment"
                                                                    className="h-25 border-0 bg-transparent"
                                                                    title={
                                                                        'Incrémenter la priorité de ' +
                                                                        element.information
                                                                    }
                                                                    disabled={element.isArchived}
                                                                    onClick={handlePriorityChange(
                                                                        element.id,
                                                                        element.priority + 1,
                                                                    )}
                                                                >
                                                                    {!element.isArchived ? (
                                                                        <AiOutlineArrowUp className="text-success" />
                                                                    ) : (
                                                                        <BsFillSignStopFill className="text-danger" />
                                                                    )}
                                                                </button>
                                                                <br />
                                                                <input
                                                                    type="range"
                                                                    min={0}
                                                                    max={props.data.length}
                                                                    onChange={(event) => {
                                                                        handleRangeChange(index, event.target.value);
                                                                    }}
                                                                    onMouseUp={handlePriorityChange(
                                                                        element.id,
                                                                        rangeValues[index],
                                                                    )}
                                                                    style={{ cursor: 'grab' }}
                                                                    defaultValue={element.priority}
                                                                />
                                                            </>
                                                        )}
                                                    </td>
                                                )}
                                                {element.isArchived === false && (
                                                    <td>
                                                        <button
                                                            value="Archive"
                                                            className="h-25 border-0 bg-transparent"
                                                            title={'Archiver ' + element.information}
                                                            onClick={handleArchiveTableSwap(element.id)}
                                                        >
                                                            <BsArchive className="text-danger" />
                                                        </button>
                                                    </td>
                                                )}
                                                {element.isArchived === true && (
                                                    <td>
                                                        <button
                                                            value="unArchive"
                                                            className="h-25 border-0 bg-transparent"
                                                            title={'Désarchiver ' + element.information}
                                                            onClick={handleArchiveTableSwap(element.id)}
                                                        >
                                                            <BsArchiveFill className="text-success" />
                                                        </button>
                                                    </td>
                                                )}
                                                {element.length <= 1 ? (
                                                    <td>
                                                        <button
                                                            value="Delete"
                                                            className="h-25 border-0 bg-transparent"
                                                            title={'Supprimer ' + element.information}
                                                            disabled
                                                        >
                                                            <BsTrash3 className="text-danger" />
                                                        </button>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <button
                                                            value="Delete"
                                                            className="h-25 border-0 bg-transparent"
                                                            title={'Supprimer ' + element.information}
                                                            onClick={handleTableDelete(element.id, element.information)}
                                                        >
                                                            <BsTrash3 className="text-danger" />
                                                        </button>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function TableManagement(props) {
    const savedCurrentTable = localStorage.getItem('tableAdminMenuIndex');
    const [currentTable, setCurrentTable] = useState((savedCurrentTable && parseInt(savedCurrentTable)) || 1);

    useEffect(() => {
        localStorage.setItem('tableAdminMenuIndex', currentTable.toString());
    }, [currentTable]);

    return (
        <div className="d-flex-column w-100">
            {/* Les boutons de choix de la table courante */}
            <div className="d-inline-flex text-align-center mt-4 mb-4">
                <button
                    onClick={(_) => {
                        setCurrentTable(1);
                    }}
                    className="btn btn-outline-secondary me-5"
                >
                    Activités
                </button>
                <button
                    onClick={(_) => {
                        setCurrentTable(2);
                    }}
                    className="btn btn-outline-secondary me-5"
                >
                    Communes
                </button>
                <button
                    onClick={(_) => {
                        setCurrentTable(3);
                    }}
                    className="btn btn-outline-secondary me-5"
                >
                    Rivières
                </button>
                <button
                    onClick={(_) => {
                        setCurrentTable(4);
                    }}
                    className="btn btn-outline-secondary"
                >
                    Véhicules
                </button>
            </div>
            <div>
                {currentTable === 1 && (
                    <TableElem
                        data={props.data.activites}
                        displayName="Activité"
                        genre="feminin"
                        database_name="activite"
                    />
                )}
                {currentTable === 2 && (
                    <TableElem
                        data={props.data.communes}
                        displayName="Commune"
                        genre="feminin"
                        database_name="commune"
                    />
                )}
                {currentTable === 3 && (
                    <TableElem
                        data={props.data.rivieres}
                        displayName="Rivière"
                        genre="feminin"
                        database_name="riviere"
                    />
                )}
                {currentTable === 4 && (
                    <TableElem
                        data={props.data.vehicules}
                        displayName="Véhicule"
                        priority={true}
                        genre="masculin"
                        database_name="vehicule"
                    />
                )}
            </div>
        </div>
    );
}
