// Fichier pour gestion des mot de passe des sessions.

import React, { useState } from 'react';
import { RequestDefaultErrorHandler, SendPOSTRequest } from '../utils/FetchUtil';
import { setAlert } from '../utils/AlertMessage';

export default function SecurityManagement() {
    const [mode, setMode] = useState('saisie');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    function handleModeChange(event) {
        setMode(event.target.value);
    }

    function handleOldPasswordChange(event) {
        setOldPassword(event.target.value);
    }

    function handleNewPasswordChange(event) {
        setNewPassword(event.target.value);
    }

    function handleConfirmPasswordChange(event) {
        setConfirmPassword(event.target.value);
    }

    /**
     * Efface tous les messages d'erreurs du formulaire.
     */
    function clearForm() {
        // retire tous les "is-invalid" des inputs
        var invalidInputs = document.querySelectorAll('input.is-invalid');
        for (let i = 0; i < invalidInputs.length; i++) {
            invalidInputs[i].classList.remove('is-invalid');
        }

        // retire tous les invalid-feedback
        var invalidFeedbacks = document.querySelectorAll('div.invalid-feedback');
        for (let i = 0; i < invalidFeedbacks.length; i++) {
            invalidFeedbacks[i].remove();
        }
    }

    /**
     * Vérifie si tous les champs sont remplis et que la confirmation du mot de passe est égale au nouveau mot de passe.
     * Le nouveau mot de passe doit être différent de l'ancien.
     * ATTENTION, la fonction ne regarde en rien ce qu'il se passe dans la base de données.
     * @returns retourne vrai si toutes les informations sont correctes
     */
    function checkInformation() {
        if (oldPassword === newPassword) {
            let inputNewPassword = document.getElementById('newPassword');
            inputNewPassword.classList.add('is-invalid');
            let divNewPassword = document.getElementById('divNewPassword');
            if (!document.getElementById('newPasswordFeedback')) {
                divNewPassword.insertAdjacentHTML(
                    'beforeend',
                    `<div id="newPasswordFeedback" class="invalid-feedback">
                Le nouveau mot de passe ne doit pas être similaire à l'ancien.
            </div>`,
                );
            }
        } else if (newPassword !== confirmPassword) {
            let inputConfirmPassword = document.getElementById('confirmPassword');
            inputConfirmPassword.classList.add('is-invalid');
            let divConfirmPassword = document.getElementById('divConfirmPassword');
            if (!document.getElementById('confirmPasswordFeedback')) {
                divConfirmPassword.insertAdjacentHTML(
                    'beforeend',
                    `<div id="confirmPasswordFeedback" class="invalid-feedback">
                    Les mots de passe ne correspondent pas.
            </div>`,
                );
            }
            let inputNewPassword = document.getElementById('newPassword');
            inputNewPassword.classList.add('is-invalid');
            let divNewPassword = document.getElementById('divNewPassword');
            if (!document.getElementById('newPasswordFeedback')) {
                divNewPassword.insertAdjacentHTML(
                    'beforeend',
                    `<div id="newPasswordFeedback" class="invalid-feedback">
                    Les mots de passe ne correspondent pas.
            </div>`,
                );
            }
        }
        return (
            oldPassword !== newPassword && newPassword === confirmPassword && oldPassword !== '' && newPassword !== ''
        );
    }

    /**
     * Soumission du formulaire de modification des mots de passes
     * @param {*} event
     */
    function handleSubmit(event) {
        event.preventDefault();
        clearForm();
        if (checkInformation()) {
            SendPOSTRequest(
                '/api/auth/change_password',
                {
                    mode: mode, //=== 'saisie' ? 2 : 1,
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                },
                (res, data) => {
                    if (res.status === 200) {
                        alert('Mot de passe modifié avec succès.');
                        window.location.reload();
                        window.scrollTo(0, 0);
                    } else if (res.status === 401) {
                        setAlert(data.error, 'danger', 'errorInputAlert');
                    }
                },
                RequestDefaultErrorHandler,
            );
        }
    }

    return (
        <div className="w-100">
            <div className="d-flex justify-content-center">
                <div className="container h-100">
                    <div className="d-flex-column h-100 justify-content-center align-items-center">
                        <div className="form-floating text-center mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                            <div className="d-flex form-check justify-content-center">
                                <h1 className="mb-5 ms-5 me-5">Sécurité</h1>
                            </div>

                            <div id="errorInputAlert" className="mt-3"></div>

                            <div className="d-flex form-check justify-content-center">
                                <h2 className="mb-5 ms-5 me-5">Changement de mot de passe</h2>
                            </div>
                            <form>
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-select"
                                        id="selectSession"
                                        onChange={handleModeChange}
                                        required
                                    >
                                        <option id="default_session" value="saisie">
                                            Saisie
                                        </option>
                                        <option value="manager">Manager</option>
                                    </select>
                                    <label htmlFor="selectSession">Choisissez la session</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input
                                        onChange={handleOldPasswordChange}
                                        type="password"
                                        id="oldPassword"
                                        className="form-control"
                                        placeholder="Ancien mot de passe"
                                    />
                                    <label className="form-label" htmlFor="oldPassword">
                                        Ancien mot de passe
                                    </label>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="form-floating" id="divNewPassword">
                                            <input
                                                onChange={handleNewPasswordChange}
                                                type="password"
                                                id="newPassword"
                                                className="form-control"
                                                placeholder="Nouveau mot de passe"
                                            />
                                            <label className="form-label" htmlFor="newPassword">
                                                Nouveau mot de passe
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="form-floating" id="divConfirmPassword">
                                            <input
                                                onChange={handleConfirmPasswordChange}
                                                type="password"
                                                id="confirmPassword"
                                                className="form-control"
                                                placeholder="Confirmer le nouveau mot de passe"
                                            />
                                            <label className="form-label" htmlFor="confirmPassword">
                                                Confirmer le nouveau mot de passe
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <input
                                    className="btn btn-success mt-3"
                                    onClick={handleSubmit}
                                    type="submit"
                                    disabled={oldPassword === '' || newPassword === '' || confirmPassword === ''}
                                    value="Confirmer"
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
