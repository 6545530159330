import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ConnectionPage from './pages/connectionPage.jsx';
import Home from './pages/homePage.jsx';
import Recherche from './pages/researchPage.jsx';
import Statistiques from './pages/statPage.jsx';
import Admin from './pages/adminPage.jsx';
import Edit from './pages/editPage.jsx';
import Consult from './pages/consultPage.jsx';
import NotFound from './pages/notFoundPage.jsx';

import PrivateRoutes from './utils/PrivateRoutes';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/" element={<ConnectionPage />} />
                    <Route element={<PrivateRoutes />}>
                        <Route path="/home" element={<Home />} />
                        <Route path="/recherche" element={<Recherche />} />
                        <Route path="/stats" element={<Statistiques />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/edit/:id" element={<Edit />} />
                        <Route path="/consult/:id" element={<Consult />} />
                    </Route>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
