import React, { useState } from 'react';
import Select from 'react-select';
import { setAlert } from '../utils/AlertMessage';
import { SendPOSTRequest, RequestDefaultErrorHandler } from '../utils/FetchUtil';

export default function ResearchForm(props) {
    const userMode = localStorage.getItem('userMode');

    const [startDate, setStartDate] = useState(new Date(1994, 10, 22).toISOString().slice(0, 10)); // la date du jour sur le PC local (date debut filtre)
    const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10)); // la date du jour sur le PC local (date fin filtre)

    const startDateRef = React.createRef();
    const endDateRef = React.createRef();

    const [communes, setCommunes] = useState([]);
    const [rivieres, setRivieres] = useState([]);
    const [activites, setActivites] = useState([]);
    const [vehicules, setVehicules] = useState([]);

    //.filter((commune) => commune.isArchived === false)
    const communeOptions = props.data.communes.map((commune) => {
        return {
            id: commune.id,
            value: commune.code,
            label: commune.information + ' (' + commune.code + ')',
        };
    });

    //.filter((riviere) => riviere.isArchived === false)
    const riviereOptions = props.data.rivieres.map((riviere) => {
        return {
            id: riviere.id,
            value: riviere.code,
            label: riviere.information + ' (' + riviere.code + ')',
        };
    });

    // .filter((activite) => activite.isArchived === false)
    const activiteOptions = props.data.activites.map((activite) => {
        return {
            id: activite.id,
            value: activite.code,
            label: activite.information + ' (' + activite.code + ')',
        };
    });

    // .filter((vehicule) => vehicule.isArchived === false)
    const vehiculeOptions = props.data.vehicules.map((vehicule) => {
        return {
            id: vehicule.id,
            value: vehicule.code,
            label: vehicule.information + ' (' + vehicule.code + ')',
        };
    });

    function updateStartDate(event) {
        setStartDate(event.target.value);
        endDateRef.current.min = event.target.value;
    }

    function updateEndDate(event) {
        setEndDate(event.target.value);
        startDateRef.current.max = event.target.value;
    }

    function handleCommuneChange(commune) {
        let shallow = [];
        // une boucle est nécessaire car on peut aussi supprimer des éléments
        for (let i = 0; i < commune.length; i++) {
            shallow.push(commune[i].value);
        }
        setCommunes(shallow);
    }

    function handleRiviereChange(riviere) {
        let shallow = [];
        for (let i = 0; i < riviere.length; i++) {
            shallow.push(riviere[i].value);
        }
        setRivieres(shallow);
    }

    function handleActiviteChange(activite) {
        let shallow = [];
        for (let i = 0; i < activite.length; i++) {
            shallow.push(activite[i].value);
        }
        setActivites(shallow);
    }

    function handleVehiculeChange(vehicule) {
        let shallow = [];
        for (let i = 0; i < vehicule.length; i++) {
            shallow.push(vehicule[i].value);
        }
        setVehicules(shallow);
    }

    function handleSubmit(event) {
        event.preventDefault();
        let res = {
            date: [startDate, endDate],
            commune: communes,
            riviere: rivieres,
            activite: activites,
            vehicule: vehicules,
        };
        //setAlert(JSON.stringify(res), 'info', 'alertMessage');
        SendPOSTRequest(
            '/api/data/get_filtered_sheets',
            res,
            (res, data) => {
                if (res.status === 200) {
                    if (parseInt(userMode) === 2) {
                        // cette partie ne concerne que l'affichage de la petite alerte verte avec le nombre de fiches trouvées
                        // l'utilisateur du mode SAISIE ne doit voir que les fiches non validées
                        let cpt = 0; // compteur de fiches non validées
                        for (let i = 0; i < data.fiches.length; i++) {
                            if (data.fiches[i].etat === 3) {
                                cpt++;
                            }
                        }
                        setAlert(
                            cpt + ' fiche' + (cpt > 1 ? 's' : '') + ' trouvée' + (cpt > 1 ? 's' : ''),
                            'success',
                            'alertMessage',
                        );
                    } else {
                        let pluriel = data.fiches.length > 1;
                        if (pluriel) {
                            if (parseInt(userMode) !== 2) {
                                setAlert(
                                    JSON.stringify(data.fiches.length) + ' fiches trouvées',
                                    'success',
                                    'alertMessage',
                                );
                            }
                        } else {
                            setAlert(JSON.stringify(data.fiches.length) + ' fiche trouvée', 'success', 'alertMessage');
                        }
                    }
                    // transmission des fiches trouvées à la page researchPage
                    props.sendDataToParent(data.fiches);
                } else {
                    setAlert('Echec de la requête.. Statut HTTP -> ' + res.status.toString(), 'danger', 'alertMessage');
                }
            },
            RequestDefaultErrorHandler,
        );
    }

    return (
        <div className="container h-100">
            <div className="d-flex-column h-100 justify-content-center align-items-center">
                <div className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                    <div className="d-flex form-check justify-content-center">
                        <h1 className="mb-5 ms-5 me-5">Formulaire de recherche</h1>
                    </div>
                    <span id="alertMessage"></span>

                    <form>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="text-secondary" htmlFor="todayDate">
                                    Date de début
                                </label>
                                <input
                                    ref={startDateRef}
                                    id="form_date"
                                    type="date"
                                    name="startDate"
                                    className="form-control text-center"
                                    value={startDate}
                                    onChange={updateStartDate}
                                    max={new Date().toISOString().slice(0, 10)}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="text-secondary" htmlFor="todayDate">
                                    Date de fin
                                </label>
                                <input
                                    ref={endDateRef}
                                    id="form_date"
                                    type="date"
                                    name="endDate"
                                    className="form-control text-center"
                                    value={endDate}
                                    onChange={updateEndDate}
                                    max={new Date().toISOString().slice(0, 10)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Select
                                    isMulti
                                    placeholder="Filtre par commune"
                                    name="filterCommune"
                                    options={communeOptions}
                                    onChange={handleCommuneChange}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Select
                                    isMulti
                                    placeholder="Filtre par rivière"
                                    name="filterRiviere"
                                    options={riviereOptions}
                                    onChange={handleRiviereChange}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </div>
                        </div>
                        <div className="form-floating mb-3">
                            <Select
                                isMulti
                                placeholder="Filtre par activité"
                                name="filterActivite"
                                options={activiteOptions}
                                onChange={handleActiviteChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </div>
                        <div className="form-floating mb-3">
                            <Select
                                isMulti
                                placeholder="Filtre par véhicule"
                                name="filterVehicule"
                                options={vehiculeOptions}
                                onChange={handleVehiculeChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </div>

                        <button type="submit" className="btn btn-success mt-3" onClick={handleSubmit}>
                            Rechercher
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
