import React from 'react';
import { RequestDefaultErrorHandler, SendPOSTRequest } from '../utils/FetchUtil';

import { BsBookmarkCheckFill } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { BsEye } from 'react-icons/bs';

export default function FicheManagement(props) {
    function handleFicheValid(id, etat) {
        return (event) => {
            event.preventDefault();
            SendPOSTRequest(
                '/api/data/change_sheet_state',
                { id: id, etat: etat },
                (res, data) => {
                    if (res.status === 200) {
                        window.location.reload();
                        window.scrollTo(0, 0);
                    } else {
                        alert('Echec de la requête.. Statut HTTP -> ' + res.status.toString());
                    }
                },
                RequestDefaultErrorHandler,
            );
        };
    }

    return (
        <div className="container h-100">
            <div className="d-flex-column h-100 justify-content-center align-items-center">
                <div className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                    <div className="d-flex form-check justify-content-center">
                        <h1 className="mb-5 ms-5 me-5">
                            Fiche{props.data.length > 1 ? 's' : '' /* 'Fiche' avec ou sans 's'*/} en attente de
                            validation
                        </h1>
                    </div>
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-md-12 column table-responsive">
                                <table className="table table-sm table-bordered table-hover table-light table-striped">
                                    <thead>
                                        <tr className="table-secondary">
                                            <th scope="col">#</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Date d'insertion</th>
                                            <th scope="col">Auteur</th>
                                            <th scope="col">Nombre de chantiers</th>
                                            <th scope="col">Nombre total d'heures</th>
                                            <th scope="col">Véhicules utilisés</th>
                                            <th scope="col">Consulter</th>
                                            <th scope="col">Modifier</th>
                                            <th scope="col">Valider</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.data.map((sheets, index) => {
                                            const tmpAttendance = sheets.attendance;
                                            let totalAttendanceHours =
                                                tmpAttendance.AccidentDuTravailTOTAL +
                                                tmpAttendance.CongeTOTAL +
                                                tmpAttendance.CongeExceptionnelTOTAL +
                                                tmpAttendance.FormationTOTAL +
                                                tmpAttendance.MaladieTOTAL +
                                                tmpAttendance.StagiaireTOTAL +
                                                tmpAttendance.TravailTOTAL +
                                                tmpAttendance.VisiteMedicalTOTAL;
                                            /**
                                             * récupération des heures de chaque activités pour vérifier la cohérence avec les heures de
                                             * présences signalées
                                             */
                                            let totalActivitiesHours = 0;
                                            sheets.chantiers.forEach((chantier) =>
                                                chantier.activites.forEach(
                                                    (activite) => (totalActivitiesHours += activite.nbHeure),
                                                ),
                                            );
                                            sheets.chantiers402.forEach((chantier) =>
                                                chantier.list.forEach(
                                                    (cha402) => (totalActivitiesHours += cha402.nbHeure),
                                                ),
                                            );

                                            totalActivitiesHours = Math.round(totalActivitiesHours * 100) / 100;

                                            return (
                                                <tr
                                                    key={sheets.id}
                                                    className={
                                                        totalActivitiesHours !==
                                                        tmpAttendance.TravailTOTAL + tmpAttendance.StagiaireTOTAL
                                                            ? 'table-danger'
                                                            : 'table-success'
                                                    }
                                                    title={
                                                        'Durée totale des activités des chantiers : ' +
                                                        totalActivitiesHours +
                                                        '\nTotal des heures de travail renseignées : ' +
                                                        (tmpAttendance.TravailTOTAL + tmpAttendance.StagiaireTOTAL)
                                                    }
                                                >
                                                    <th id={sheets.id}>{index + 1}</th>
                                                    <td>
                                                        {new Date(sheets.date).toLocaleDateString('fr-FR', {
                                                            weekday: 'long',
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                        })}
                                                    </td>
                                                    <td>
                                                        {(sheets.date_ajout &&
                                                            new Date(sheets.date_ajout).toLocaleDateString('fr-FR', {
                                                                weekday: 'long',
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric',
                                                            })) ||
                                                            'N/A'}
                                                    </td>
                                                    <td>{sheets.user.user_name}</td>
                                                    <td>{sheets.chantiers.length}</td>
                                                    <td>{totalAttendanceHours}</td>
                                                    <td>{sheets.vehicules.length}</td>
                                                    <td>
                                                        <button
                                                            value="Consulter"
                                                            className="h-25 border-0 bg-transparent"
                                                            title="Consulter"
                                                            onClick={() => {
                                                                window.open('/consult/' + sheets.id, '_blank');
                                                            }}
                                                        >
                                                            <BsEye className="text-primary" />
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            value="Modifier"
                                                            className="h-25 border-0 bg-transparent"
                                                            title={'Modif ' + sheets.id}
                                                            onClick={() => {
                                                                window.location.replace('/edit/' + sheets.id);
                                                            }}
                                                        >
                                                            <FiEdit className="text-primary" />
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            value="Verif"
                                                            className="h-25 border-0 bg-transparent"
                                                            title={'Verif ' + sheets.id}
                                                            onClick={
                                                                handleFicheValid(sheets.id, 1) // 1 pour état VALIDE
                                                            }
                                                        >
                                                            <BsBookmarkCheckFill className="text-success" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
