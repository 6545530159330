/**
 * Gère l'expiration de la session utilisateur.
 * @returns {boolean} `true`.
 */
function handleExpiredSession() {
    localStorage.clear();
    window.location.replace('/');
    alert('La session a expirée.');
    return true;
}

export default handleExpiredSession;
