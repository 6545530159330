import React, { useState } from 'react';
import { RequestDefaultErrorHandler, SendPOSTRequest } from '../utils/FetchUtil';

import { BsArchive, BsArchiveFill, BsTrash3, BsPersonFillLock } from 'react-icons/bs';

export default function TeamManagement(props) {
    const [mode, setMode] = useState('saisie');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');

    // tri par statut d'archivage
    props.data.sort((a, b) => {
        if (a.isArchived && b.isArchived === false) {
            return 1;
        }
        if (a.isArchived === false && b.isArchived) {
            return -1;
        }
        return 0;
    });

    function handleModeChange(event) {
        setMode(event.target.value);
    }

    function handleFirstnameChange(event) {
        setFirstname(event.target.value);
    }

    function handleLastnameChange(event) {
        setLastname(event.target.value);
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
        SendPOSTRequest(
            '/api/data/add_user',
            {
                mode: mode,
                firstname: firstname,
                lastname: lastname,
                email: email,
            },
            (res, data) => {
                if (res.status === 200) {
                    alert("Succès de l'envoi de l'utilisateur");
                    window.location.reload();
                    window.scrollTo(0, 0);
                } else {
                    alert('Echec de la requête.. Statut HTTP ->' + res.status.toString());
                }
            },
            RequestDefaultErrorHandler,
        );
    }

    function handleDelete(id, name) {
        return (event) => {
            if (window.confirm('Voulez-vous vraiment supprimer ' + name + ' ?')) {
                event.preventDefault();
                SendPOSTRequest(
                    '/api/data/delete_user',
                    { id: id },
                    (res, data) => {
                        if (res.status === 200) {
                            alert("Succès de la suppression de l'utilisateur");
                            window.location.reload();
                            window.scrollTo(0, 0);
                        } else {
                            alert('Echec de la requête.. Statut HTTP ->' + res.status.toString());
                        }
                    },
                    RequestDefaultErrorHandler,
                );
            }
        };
    }

    function handleArchiveSwap(id) {
        return (event) => {
            event.preventDefault();
            SendPOSTRequest(
                '/api/data/change_archive_status',
                { id: id },
                (res, data) => {
                    if (res.status === 200) {
                        //alert("L'utilisateur " + (data.isArchived ? 'a bien été' : "n'est plus") + ' archivé.');
                        window.location.reload();
                        window.scrollTo(0, 0);
                    } else {
                        alert('Echec de la requête.. Statut HTTP -> ' + res.status.toString());
                    }
                },
                RequestDefaultErrorHandler,
            );
        };
    }

    return (
        <div className="container h-100">
            <div className="d-flex-column h-100 justify-content-center align-items-center">
                <div className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                    <div className="d-flex form-check justify-content-center">
                        <h1 className="mb-5 ms-5 me-5">Liste des membres de l'équipe</h1>
                    </div>

                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-md-12 column table-responsive">
                                <table className="table table-sm table-bordered table-hover table-light table-striped">
                                    <thead>
                                        <tr className="table-secondary">
                                            <th scope="col">#</th>
                                            <th scope="col">Nom</th>
                                            <th scope="col">Mail</th>
                                            <th scope="col">Session</th>
                                            <th scope="col">Archivage</th>
                                            <th scope="col">Suppression</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.data.map((user, index) => {
                                            return (
                                                <tr key={user.id}>
                                                    <th id={user.id}>{index + 1}</th>
                                                    <td>{user.fullName}</td>
                                                    <td>{user.email}</td>
                                                    {user.mode === 1 && (
                                                        <td className="fst-italic fw-bold fs-6">Manager</td>
                                                    )}
                                                    {user.mode === 2 && <td className="fst-italic fs-6">Saisie</td>}
                                                    {user.isArchived === false && (
                                                        <td>
                                                            <button
                                                                value="Archive"
                                                                className="h-25 border-0 bg-transparent"
                                                                title={'Archiver ' + user.fullName}
                                                                onClick={handleArchiveSwap(user.id)}
                                                            >
                                                                <BsArchive className="text-danger" />
                                                            </button>
                                                        </td>
                                                    )}
                                                    {user.isArchived === true && (
                                                        <td>
                                                            <button
                                                                value="unArchive"
                                                                className="h-25 border-0 bg-transparent"
                                                                title={'Désarchiver ' + user.fullName}
                                                                onClick={handleArchiveSwap(user.id)}
                                                            >
                                                                <BsArchiveFill className="text-success" />
                                                            </button>
                                                        </td>
                                                    )}
                                                    {user.mode !== 1 ? (
                                                        /* IL NE FAUT PAS SUPPRIMER LE MANAGER */ <td>
                                                            <button
                                                                value="Delete"
                                                                className="h-25 border-0 bg-transparent"
                                                                title={'Supprimer ' + user.fullName}
                                                                onClick={handleDelete(user.id, user.fullName)}
                                                            >
                                                                <BsTrash3 className="text-danger" />
                                                            </button>
                                                        </td>
                                                    ) : (
                                                        <td>
                                                            <button
                                                                value=""
                                                                className="h-25 border-0 bg-transparent"
                                                                title={'Manager : ' + user.fullName}
                                                                disabled
                                                            >
                                                                <BsPersonFillLock className="text-dark" />
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                    <div className="d-flex form-check justify-content-center">
                        <h1 className="mb-5 ms-5 me-5">Ajouter un membre</h1>
                    </div>

                    <form>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input
                                        onChange={handleFirstnameChange}
                                        type="text"
                                        id="firstName"
                                        className="form-control"
                                        placeholder="Prénom"
                                    />
                                    <label className="form-label" htmlFor="firstName">
                                        Prénom
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input
                                        onChange={handleLastnameChange}
                                        type="text"
                                        id="lastName"
                                        className="form-control"
                                        placeholder="Nom"
                                    />
                                    <label className="form-label" htmlFor="lastName">
                                        Nom
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="form-floating mb-3">
                            <input
                                onChange={handleEmailChange}
                                type="email"
                                id="emailAddress"
                                className="form-control"
                                placeholder="E-mail"
                            />
                            <label className="form-label" htmlFor="emailAddress">
                                E-mail
                            </label>
                        </div>

                        <div className="form-floating mb-3">
                            <select
                                onChange={handleModeChange}
                                className="form-select"
                                id="memberSelectSession"
                                required
                            >
                                <option id="default_session" value="saisie">
                                    Saisie
                                </option>
                                <option value="manager">Manager</option>
                            </select>
                            <label htmlFor="floatingSelectSession">Choisissez la session</label>
                        </div>

                        <input className="btn btn-success mt-3" onClick={handleSubmit} type="submit" value="Ajouter" />
                    </form>
                </div>
            </div>
        </div>
    );
}
