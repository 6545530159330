import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import handleExpiredSession from '../utils/Session';
import AsideMenu from '../components/sideMenu';
import TeamManagement from '../components/teamManagement';
import TableManagement from '../components/tableManagement';
import FicheManagement from '../components/ficheManagement';
import HourManagement from '../components/hourManagement';
import Form402Management from '../components/form402Management';
import DependanceManagement from '../components/dependanceManagement';
import { SendGETRequest } from '../utils/FetchUtil';
import SecurityManagement from '../components/securityManagement';

export default function Admin() {
    const savedCurrentMenu = localStorage.getItem('adminMenuIndex');
    const [currentMenu, setCurrentMenu] = useState((savedCurrentMenu && parseInt(savedCurrentMenu)) || 1);
    function setMenuValue(value) {
        setCurrentMenu(value);
    }

    useEffect(() => {
        localStorage.setItem('adminMenuIndex', currentMenu.toString());
    }, [currentMenu]);

    // récupération des données de la base depuis le serveur : Site-CISALB-server\src\routes\data\index.js
    const [isLoading, setIsLoading] = useState(true);
    const [apiInformationData, setApiInformationData] = useState({});
    const [apiInformationError, setApiInformationError] = useState(undefined);

    useEffect(() => {
        SendGETRequest('/api/data/get_all_information', setApiInformationData, setApiInformationError, setIsLoading);
    }, []);

    const [isLoadingSheets, setIsLoadingSheets] = useState(true);
    const [apiSheetsData, setApiSheetsData] = useState({});
    const [apiSheetsError, setApiSheetsError] = useState(undefined);

    function hasExpiredError() {
        return (
            !isLoading &&
            !isLoadingSheets &&
            apiInformationData &&
            apiSheetsData &&
            (apiInformationError || apiSheetsError) &&
            (apiInformationData.tokenExpired || apiSheetsData.tokenExpired)
        );
    }

    function hasOtherError() {
        return !isLoading && !isLoadingSheets && (apiInformationError || apiSheetsError);
    }

    useEffect(() => {
        SendGETRequest('/api/data/get_all_waiting_sheets', setApiSheetsData, setApiSheetsError, setIsLoadingSheets);
    }, []);

    return (
        <div>
            <Header />

            {isLoading && isLoadingSheets && (
                <div className="position-fixed p-2 w-100">
                    <div className="alert alert-primary" role="alert">
                        Chargement des utilisateurs...
                        <br />
                        <div className="spinner-grow text-primary m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                        <div className="spinner-grow text-light m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                        <div className="spinner-grow text-danger m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                    </div>
                </div>
            )}

            {!isLoading && !isLoadingSheets && !apiInformationError && !apiSheetsError && (
                // transmission des infos utilisateur au composant teamManagement.jsx
                <div className="d-flex">
                    <AsideMenu
                        menuValue={currentMenu}
                        setCurrentMenu={setMenuValue}
                        numWaitingSheets={apiSheetsData.length}
                    />
                    {currentMenu === 1 && <FicheManagement data={apiSheetsData} />}
                    {currentMenu === 2 && <TeamManagement data={apiInformationData.persons} />}
                    {currentMenu === 3 && <TableManagement data={apiInformationData} />}
                    {currentMenu === 4 && <HourManagement />}
                    {currentMenu === 5 && (
                        <Form402Management
                            data={apiInformationData}
                            data402={apiInformationData.parametres['liste_402']}
                        />
                    )}
                    {currentMenu === 6 && <DependanceManagement data={apiInformationData} />}
                    {currentMenu === 7 && <SecurityManagement />}
                </div>
            )}

            {(hasExpiredError() && handleExpiredSession()) ||
                (hasOtherError() && (
                    <div
                        className="alert alert-danger mt-5 ms-5 me-5"
                        role="alert"
                        onClick={(_) => {
                            window.location.reload();
                        }}
                    >
                        <h4 className="alert-heading">Erreur!</h4>
                        <p>
                            Il semblerait qu'il y ait eu une erreur lors de la récupération des données sur le serveur.
                            Si cela continue après le rafraîchissement de la page, essayez de vous déconnecter et de
                            vous reconnecter.
                        </p>
                        <p>Si cela ne fonctionne toujours pas, contactez votre administrateur réseau..</p>
                        <p>
                            <strong>Description: </strong> {apiInformationError.error}
                        </p>
                        <hr />
                        <p className="mb-0">Cliquez sur l'encadré pour rafraîchir la page.</p>
                    </div>
                ))}
        </div>
    );
}
