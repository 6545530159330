import handleExpiredSession from './Session';

/**
 * Envoie une requête POST à l'URL cible avec le corps spécifié.
 * @param {string} targetUrl - L'URL cible de la requête POST.
 * @param {Object} body - Le corps de la requête.
 * @param {function} onRequestEnd - Fonction de rappel appelée lorsque la requête se termine avec succès.
 * @param {function} onRequestError - Fonction de rappel appelée en cas d'erreur lors de la requête.
 */
export function SendPOSTRequest(targetUrl, body, onRequestEnd, onRequestError) {
    let requestResult;
    fetch(targetUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(body),
    })
        .then((res) => {
            requestResult = res;
            return res.json();
        })
        .then((data) => {
            if (data.tokenExpired) {
                handleExpiredSession();
            } else {
                onRequestEnd(requestResult, data);
            }
        })
        .catch((err) => {
            onRequestError(err);
        });
}

/**
 * Envoie une requête POST à l'URL cible avec le corps spécifié et renvoie un objet Blob.
 * @param {string} targetUrl - L'URL cible de la requête POST.
 * @param {Object} body - Le corps de la requête.
 * @param {function} onRequestEnd - Fonction de rappel appelée lorsque la requête se termine avec succès.
 * @param {function} onRequestError - Fonction de rappel appelée en cas d'erreur lors de la requête.
 */
export function SendPOSTRequestBlob(targetUrl, body, onRequestEnd, onRequestError) {
    let requestResult;
    fetch(targetUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(body),
    })
        .then((res) => {
            requestResult = res;
            return res.blob();
        })
        .then((data) => {
            if (data.tokenExpired) {
                handleExpiredSession();
            } else {
                onRequestEnd(requestResult, data);
            }
        })
        .catch((err) => {
            onRequestError(err);
        });
}

/**
 * Envoie une requête GET à l'URL cible.
 * @param {string} targetUrl - L'URL cible de la requête GET.
 * @param {function} setDataFunc - Fonction de rappel appelée pour définir les données reçues.
 * @param {function} setErrFunc - Fonction de rappel appelée pour définir l'erreur en cas d'erreur lors de la requête.
 * @param {function} setLoadingFunc - Fonction de rappel appelée pour définir l'état de chargement de la requête.
 */
export function SendGETRequest(targetUrl, setDataFunc, setErrFunc, setLoadingFunc) {
    let requestResult;
    fetch(targetUrl, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    })
        .then((res) => {
            requestResult = res;
            return res.json();
        })
        .then((data) => {
            if (data.tokenExpired) {
                handleExpiredSession();
            }

            setDataFunc(data);
            if (requestResult.status !== 200) {
                setErrFunc(data);
            }
        })
        .catch((err) => {
            setErrFunc(err);
            setLoadingFunc(false);
        })
        .finally(() => {
            setLoadingFunc(false);
        });
}

/**
 * Télécharge un fichier à partir de l'URL cible.
 * @param {string} targetUrl - L'URL cible du fichier à télécharger.
 * @returns {Object} Un objet contenant le résultat de la requête, les données reçues et une erreur en cas d'erreur lors de la requête.
 */
export async function DownloadFile(targetUrl) {
    let requestResult, data;
    let err = undefined;
    try {
        requestResult = await fetch(targetUrl, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        });
        data = await requestResult.blob();
    } catch (caughtErr) {
        err = caughtErr;
    }
    return { requestResult, data, err };
}

/**
 * Gère les erreurs de requête par défaut.
 * @param {Error} err - L'erreur à gérer.
 */
export function RequestDefaultErrorHandler(err) {
    console.error(err);
    alert('Erreur liée au réseau ...');
}
