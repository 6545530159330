// Tableau gestion des activités sur formulaire d'intervention
// Avec possibilité d'ajouter et de supprimer une ligne

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { convertHourGood } from './attendanceForm';

export default function TableActivity(props) {
    const [activites, setActivites] = useState([{}]);

    function addActiviteToArray(activite) {
        let shallow = [...activites];
        shallow.push(activite);
        setActivites(shallow);
    }

    function delActiviteFromArray() {
        let shallow = [...activites];
        let activite = shallow.pop();
        setActivites(shallow);
        return activite;
    }

    function handleActiviteChange(index) {
        return (e) => {
            activites[index].activite = {
                id: e.id,
                code: e.value,
                information: e.label,
            };
            props.handleActivityChange(activites);
        };
    }

    function handleHourChange(index) {
        return (e) => {
            activites[index].nbHeure = e.target.value;
            props.handleActivityChange(activites);
        };
    }

    function incrementNoRows() {
        addActiviteToArray({});
    }

    function decrementNoRows() {
        delActiviteFromArray();
    }

    const updateParentFunc = props.handleActivityChange;
    useEffect(() => {
        updateParentFunc(activites);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activites]);

    useEffect(() => {
        if (props.presetData) {
            let tmpActivites = [];
            props.presetData.forEach((act) => {
                tmpActivites.push({
                    activite: act,
                    nbHeure: convertHourGood(act.nbHeure),
                });
            });
            setActivites(tmpActivites);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //use of hook for couting

    const activiteOptions = props.data
        .filter((activite) => activite.isArchived === false)
        .map((activite) => {
            return {
                id: activite.id,
                value: activite.code,
                label: activite.information + ' (' + activite.code + ')',
            };
        })
        .sort((a, b) => a.label.localeCompare(b.label)); // tri par ordre alphabétique

    return (
        <div className="container p-5">
            <table className="table table-hover table-bordered p-5 table-default table-striped">
                <thead>
                    <tr className="table-secondary">
                        <th scope="col">#</th>
                        <th scope="col">Activités</th>
                        <th scope="col align-center">Nombre d'heures</th>
                    </tr>
                </thead>
                <tbody>
                    {activites.map((act, index) => {
                        return (
                            <tr key={index + 1}>
                                <th scope="row" className="align-middle">
                                    {index + 1}
                                </th>
                                <td>
                                    <div className="form-floating">
                                        <Select
                                            placeholder="Choisissez l'activité"
                                            id="selectActivity"
                                            onChange={handleActiviteChange(index)}
                                            required
                                            options={activiteOptions}
                                            defaultValue={
                                                act.activite &&
                                                activiteOptions.find((opt) => opt.value === act.activite.code)
                                            }
                                            key={
                                                act.activite &&
                                                activiteOptions.find((opt) => opt.value === act.activite.code)
                                            }
                                        />
                                    </div>
                                </td>
                                <td className="align-middle">
                                    <input
                                        className="text-center"
                                        type="number"
                                        name="nbHeure"
                                        id="Heures"
                                        value={act.nbHeure || ''}
                                        min={0}
                                        onChange={handleHourChange(index)}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <button
                disabled={activites.length === 1}
                type="button"
                className="btn btn-outline-danger"
                onClick={decrementNoRows}
            >
                Supprimer ligne
            </button>
            <button type="button" className="btn btn-outline-success ms-5" onClick={incrementNoRows}>
                Ajouter ligne
            </button>
        </div>
    );
}
