// Fichier pour getsion des heures de chaque jours de la semaine.

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { SendPOSTRequest, RequestDefaultErrorHandler } from '../utils/FetchUtil';

import { BsTrash3 } from 'react-icons/bs';

export default function Form402Management(props) {
    const [riviereOptions, setRiviereOptions] = useState([]);
    const [grille, setGrille] = useState(['']);

    const [currentRiviere, setCurrentRiviere] = useState(undefined);
    const [currentCommune, setCurrentCommune] = useState(undefined);

    const communeOptions = props.data.communes
        .filter((commune) => commune.isArchived === false)
        .map((commune) => {
            return {
                id: commune.id,
                value: commune.code,
                label: commune.information + ' (' + commune.code + ')',
            };
        });

    function handleCommuneChange(event) {
        setCurrentCommune(event.value);
        // quand la commune change, toutes les options doivent être effacées
        resetRiviereOptions(event.value);
    }

    function handleRiviereChange(event) {
        setCurrentRiviere(event.value);
    }

    /**
     * Met à jour rivieresOptions suivant la commune choisie => seulement les rivières qui passent par la commune
     */
    function resetRiviereOptions(codeCommune) {
        let allCommunes = props.data.communes;
        let codesRiviere = [];
        for (let i = 0; i < allCommunes.length; i++) {
            if (codeCommune === allCommunes[i].code) {
                for (let j = 0; j < allCommunes[i].rivieres.length; j++) {
                    codesRiviere.push(allCommunes[i].rivieres[j].code);
                }
                break;
            }
        }
        let res = props.data.rivieres
            .filter((riviere) => riviere.isArchived === false && codesRiviere.includes(riviere.code))
            .map((riviere) => {
                return {
                    id: riviere.id,
                    value: riviere.code,
                    label: riviere.information + ' (' + riviere.code + ')',
                };
            });

        // Mise à jour du select des rivières
        setRiviereOptions(res);
    }

    useEffect(() => {
        setRiviereOptions(
            props.data.rivieres
                .filter((riviere) => riviere.isArchived === false)
                .map((riviere) => {
                    return {
                        id: riviere.id,
                        value: riviere.code,
                        label: riviere.information + ' (' + riviere.code + ')',
                    };
                }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleGrilleChange(idx) {
        return (event) => {
            let shallow = [...grille];
            shallow[idx] = event.target.value;
            setGrille(shallow);
        };
    }

    function decrementNoRows() {
        let shallow = [...grille];
        shallow.pop();
        setGrille(shallow);
    }

    function incrementNoRows() {
        let shallow = [...grille];
        shallow.push('');
        setGrille(shallow);
    }

    function handleAdd402Elem(event) {
        event.preventDefault();

        let commune = communeOptions.find((comm) => comm.value === currentCommune);
        let riviere = riviereOptions.find((riv) => riv.value === currentRiviere);

        let data = [...props.data402];
        data.push({
            commune: (commune && commune.label) || '',
            riviere: (riviere && riviere.label) || '',
            grille: grille,
        });

        SendPOSTRequest(
            '/api/data/put_parametre',
            { type: 'liste_402', data: data },
            (res, data) => {
                if (res.status === 200) {
                    window.location.reload();
                    window.scrollTo(0, 0);
                } else {
                    alert('Echec de la requête.. Statut HTTP -> ' + data.error);
                }
            },
            RequestDefaultErrorHandler,
        );
    }

    function handleDel402Elem(idx) {
        return (event) => {
            event.preventDefault();
            let data = [...props.data402];
            data.splice(idx, 1);
            SendPOSTRequest(
                '/api/data/put_parametre',
                { type: 'liste_402', data: data },
                (res, data) => {
                    if (res.status === 200) {
                        window.location.reload();
                        window.scrollTo(0, 0);
                    } else {
                        alert('Echec de la requête.. Statut HTTP -> ' + data.error);
                    }
                },
                RequestDefaultErrorHandler,
            );
        };
    }

    return (
        <div className="container h-100">
            <div className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                <div className="d-flex form-check justify-content-center">
                    <h1 className="mb-5 ms-5 me-5">Édition du formulaire 402</h1>
                </div>

                <div className="d-flex form-check justify-content-center">
                    <h2 className="mb-5 ms-5 me-5">Ajouter une ligne</h2>
                </div>
                <form>
                    <div className="row">
                        <div className="form-floating mb-3">
                            <Select
                                placeholder="Choisissez la commune"
                                id="form_selectCommune"
                                onChange={handleCommuneChange}
                                required
                                options={communeOptions}
                                defaultValue={currentCommune}
                            />
                        </div>

                        <div className="form-floating mb-3">
                            <Select
                                placeholder="Choisissez la rivière"
                                id={'form_selectRiviere_' + props.cIdx}
                                onChange={handleRiviereChange}
                                required
                                options={riviereOptions}
                                defaultValue={currentRiviere}
                            />
                        </div>
                        {grille.map((elem, idx) => {
                            return (
                                <div className="d-flex justify-content-center" key={idx}>
                                    <div className="col-md-10 mb-3">
                                        <div className="form-floating">
                                            <input
                                                required
                                                type="text"
                                                id="activityDescription"
                                                className="form-control"
                                                placeholder="Description"
                                                defaultValue={elem}
                                                onChange={handleGrilleChange(idx)}
                                            />
                                            <label className="form-label" htmlFor="activityDescription">
                                                Élément grille n°{idx + 1}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="d-flex justify-content-center">
                            <button
                                disabled={grille.length === 1}
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={decrementNoRows}
                            >
                                Supprimer ligne
                            </button>
                            <button type="button" className="btn btn-outline-success ms-5" onClick={incrementNoRows}>
                                Ajouter ligne
                            </button>
                        </div>
                    </div>

                    <input className="btn btn-success mt-3" type="submit" value="Ajouter" onClick={handleAdd402Elem} />
                </form>
            </div>
            <div className="d-flex-column h-100 justify-content-center align-items-center">
                <div className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                    <h5 className="mb-3">Éditeur du Formulaire code 402</h5>
                    <div>
                        <div className="container">
                            <div className="row clearfix">
                                <div className="col-md-12 column table-responsive">
                                    <table className="table table-sm table-bordered table-hover table-light table-striped align-middle">
                                        <thead>
                                            <tr className="table-secondary">
                                                <th scope="col">#</th>
                                                <th scope="col">Commune</th>
                                                <th scope="col">Rivière</th>
                                                <th scope="col">Grille</th>
                                                <th scope="col">Supprimer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.data402.map((ent, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <th scope="row">{idx + 1}</th>
                                                        <td>{ent.commune}</td>
                                                        <td>{ent.riviere}</td>
                                                        <td>
                                                            {ent.grille.map((grille, idx2) => {
                                                                return (
                                                                    <span key={idx2}>
                                                                        {grille}{' '}
                                                                        {idx2 !== ent.grille.length - 1 && <br />}
                                                                    </span>
                                                                );
                                                            })}
                                                        </td>
                                                        <td>
                                                            <button
                                                                value=""
                                                                className="h-25 border-0 bg-transparent"
                                                                title={'Supprimer entrée 402 n°' + idx}
                                                                onClick={handleDel402Elem(idx)}
                                                            >
                                                                <BsTrash3 className="text-danger" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
