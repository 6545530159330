import { Navigate, Outlet } from 'react-router-dom';

/**
 * Vérifie si l'utilisateur est connecté.
 * @returns {boolean} `true` si l'utilisateur est connecté, `false` sinon.
 */
function isLoggedIn() {
    let token = localStorage.getItem('token');
    let userMode = localStorage.getItem('userMode');
    if (token && userMode) {
        if (userMode === '1' || userMode === '2') {
            return true;
        }
    }
    return false;
}

const PrivateRoutes = ({ children, ...rest }) => {
    return isLoggedIn() ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
