/**
 * Utilitaire pour les messages d'alertes Bootstrap
 */

/**
 * Affiche une alerte avec un message et une couleur (Bootstrap) spécifiés dans un élément HTML avec l'ID spécifié.
 * @param {string} message - Le message à afficher dans l'alerte.
 * @param {string} - La couleur de l'alerte (par défaut : 'warning').
 * @param {string} id - L'ID de l'élément HTML dans lequel afficher l'alerte.
 */
export function setAlert(message, color = 'warning', id) {
    let element = document.getElementById(id);
    if (message.length > 0 && element) {
        element.innerHTML = `
            <div class="alert alert-${color} alert-dismissible fade show fw-bold" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                ${message}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `;
    } else if (element) {
        element.innerHTML = ``;
    }
}

/**
 * Efface le contenu d'un élément HTML avec l'ID spécifié.
 * @param {string} id - L'ID de l'élément HTML à effacer.
 */
export function clearAlert(id) {
    let element = document.getElementById(id);
    if (element) element.innerHTML = '';
}
