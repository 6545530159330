import React, { useEffect, useState } from 'react';
import handleExpiredSession from '../utils/Session';
import { RequestDefaultErrorHandler, SendPOSTRequest, SendPOSTRequestBlob } from '../utils/FetchUtil';
import { setAlert } from '../utils/AlertMessage';
import { convertHourGood } from './attendanceForm';

import { BsEye } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { BsTrash3 } from 'react-icons/bs';
import { CiExport } from 'react-icons/ci';

import Dropdown from 'react-bootstrap/Dropdown';

export default function ResearchResults(props) {
    const userMode = localStorage.getItem('userMode');

    const [isLoading, setIsLoading] = useState(true);
    const [apiInformationData, setApiInformationData] = useState({});
    const [apiInformationError, setApiInformationError] = useState(undefined);
    const fichesResult = props.filteredData;

    function hasExpiredError() {
        return !isLoading && apiInformationData && apiInformationError && apiInformationData.tokenExpired;
    }

    function hasOtherError() {
        return !isLoading && apiInformationError;
    }

    useEffect(() => {
        if (fichesResult) {
            /*SendGETRequest(
                '/api/data/get_all_valid_sheets',
                setApiInformationData,
                setApiInformationError,
                setIsLoading,
            );*/
            setApiInformationData(fichesResult);
            setIsLoading(false);
            setApiInformationError(false);
            window.scrollTo({
                top: window.innerHeight,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [fichesResult]);

    function handleDelete(id, date) {
        return (event) => {
            date = new Date(date).toLocaleDateString('fr-FR', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
            if (window.confirm('Voulez-vous vraiment supprimer la fiche du ' + date + ' ?')) {
                event.preventDefault();
                SendPOSTRequest(
                    '/api/data/delete_sheet',
                    { id: id },
                    (res, data) => {
                        if (res.status === 200) {
                            setAlert('Suppression de la fiche réussie.', 'success', 'alertSearchResult');
                            window.location.reload();
                            window.scrollTo(0, 0);
                        } else {
                            setAlert(
                                'Echec de la requête.. Statut HTTP -> ' + res.status.toString(),
                                'danger',
                                'alertSearchResult',
                            );
                        }
                    },
                    RequestDefaultErrorHandler,
                );
            }
        };
    }

    // transmet la liste des IDs des fiches au serveur
    async function exportAs(type) {
        var lstID = [];
        for (let i = 0; i < fichesResult.length; i++) {
            lstID.push(fichesResult[i].id);
        }

        SendPOSTRequestBlob(
            '/api/data/export_all_sheets',
            { type: type, listId: lstID },
            (res, data) => {
                if (res.status === 200) {
                    var url = window.URL || window.webkitURL;
                    var link = url.createObjectURL(data);
                    var a = document.createElement('a');
                    a.setAttribute('download', 'export.' + type);
                    a.setAttribute('href', link);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    setAlert("Echec de l'export en " + type, 'danger', 'alertSearchResult');
                }
            },
            RequestDefaultErrorHandler,
        );
    }

    return (
        <>
            {isLoading && (
                <div className="position-fixed p-2 w-100">
                    <div className="alert alert-primary" role="alert">
                        Chargement des informations...
                        <br />
                        <div className="spinner-grow text-primary m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                        <div className="spinner-grow text-light m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                        <div className="spinner-grow text-danger m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                    </div>
                </div>
            )}

            {!isLoading && !apiInformationError && (
                <>
                    <div className="container h-100">
                        <div className="d-flex-column h-100 justify-content-center align-items-center">
                            <div className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                                <div className="d-flex flex-column form-check justify-content-center">
                                    <h1 className="mb-1 ms-5 me-5">
                                        Résultat{apiInformationData.length > 1 ? 's' : '' /* 'Fiche' avec ou sans 's'*/}{' '}
                                        de la recherche
                                    </h1>
                                    <span id="alertSearchResult" className="mb-2"></span>
                                    <div className="mb-3">
                                        <Dropdown className="ms-3">
                                            <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
                                                {fichesResult.length > 1 ? 'Exporter les fiches' : 'Exporter la fiche'}
                                                <CiExport size={25} className="ms-2" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        exportAs('csv');
                                                    }}
                                                    href="#"
                                                >
                                                    Exporter vers <strong>CSV</strong>
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        exportAs('xlsx');
                                                    }}
                                                    href="#"
                                                >
                                                    Exporter vers <strong>XLSX</strong>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="row clearfix">
                                        <div className="col-md-12 column table-responsive">
                                            <table className="table table-sm table-bordered table-hover table-light table-striped">
                                                <thead>
                                                    <tr className="table-secondary">
                                                        <th scope="col">#</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Auteur</th>
                                                        <th scope="col">Nombre de chantiers</th>
                                                        <th scope="col">Nombre total d'heures</th>
                                                        <th scope="col">Véhicules utilisés</th>
                                                        <th scope="col">Consulter</th>
                                                        <th scope="col">Modifier</th>
                                                        {userMode === '1' && (
                                                            <>
                                                                <th scope="col">Supprimer</th>
                                                            </>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {apiInformationData.map((sheets, index) => {
                                                        const tmpAttendance = sheets.attendance;
                                                        let totalAttendanceHours =
                                                            tmpAttendance.AccidentDuTravailTOTAL +
                                                            tmpAttendance.CongeTOTAL +
                                                            tmpAttendance.CongeExceptionnelTOTAL +
                                                            tmpAttendance.FormationTOTAL +
                                                            tmpAttendance.MaladieTOTAL +
                                                            tmpAttendance.StagiaireTOTAL +
                                                            tmpAttendance.TravailTOTAL +
                                                            tmpAttendance.VisiteMedicalTOTAL;
                                                        /**
                                                         * récupération des heures de chaque activités pour vérifier la cohérence avec les heures de
                                                         * présences signalées
                                                         */
                                                        let totalActivitiesHours = 0;
                                                        sheets.chantiers.forEach((chantier) =>
                                                            chantier.activites.forEach(
                                                                (activite) =>
                                                                    (totalActivitiesHours += convertHourGood(activite.nbHeure)),
                                                            ),
                                                        );

                                                        return (
                                                            <tr
                                                                key={sheets.id}
                                                                title={
                                                                    'Durée totale des activités des chantiers : ' +
                                                                    totalActivitiesHours +
                                                                    '\nTotal des heures de travail renseignées : ' +
                                                                    parseFloat(
                                                                        tmpAttendance.StagiaireTOTAL +
                                                                            tmpAttendance.TravailTOTAL,
                                                                    )
                                                                }
                                                            >
                                                                <th id={sheets.id}>{index + 1}</th>
                                                                <td>
                                                                    {new Date(sheets.date).toLocaleDateString('fr-FR', {
                                                                        weekday: 'long',
                                                                        year: 'numeric',
                                                                        month: 'long',
                                                                        day: 'numeric',
                                                                    })}
                                                                </td>
                                                                <td>{sheets.user.user_name}</td>
                                                                <td>{sheets.chantiers.length}</td>
                                                                <td>{totalAttendanceHours}</td>
                                                                <td>{sheets.vehicules.length}</td>
                                                                <td>
                                                                    <button
                                                                        value="Consulter"
                                                                        className="h-25 border-0 bg-transparent"
                                                                        title="Consulter"
                                                                        onClick={() => {
                                                                            window.open(
                                                                                '/consult/' + sheets.id,
                                                                                '_blank',
                                                                            );
                                                                        }}
                                                                    >
                                                                        <BsEye className="text-primary" />
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        value="Modifier"
                                                                        className="h-25 border-0 bg-transparent"
                                                                        title="Modifier"
                                                                        onClick={() => {
                                                                            window.location.replace(
                                                                                '/edit/' + sheets.id,
                                                                            );
                                                                        }}
                                                                    >
                                                                        <FiEdit className="text-primary" />
                                                                    </button>
                                                                </td>
                                                                {userMode === '1' && (
                                                                    <>
                                                                        <td>
                                                                            <button
                                                                                value="Delete"
                                                                                className="h-25 border-0 bg-transparent"
                                                                                title="Supprimer"
                                                                                onClick={handleDelete(
                                                                                    sheets.id,
                                                                                    sheets.date,
                                                                                )}
                                                                            >
                                                                                <BsTrash3 className="text-danger" />
                                                                            </button>
                                                                        </td>
                                                                    </>
                                                                )}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {(hasExpiredError() && handleExpiredSession()) ||
                (hasOtherError() && (
                    <div
                        className="alert alert-danger mt-5 ms-5 me-5"
                        role="alert"
                        onClick={(_) => {
                            window.location.reload();
                        }}
                    >
                        <h4 className="alert-heading">Erreur!</h4>
                        <p>
                            Il semblerait qu'il y ait eu une erreur lors de la récupération des données sur le serveur.
                            Si cela continue après le rafraîchissement de la page, essayez de vous déconnecter et de
                            vous reconnecter.
                        </p>
                        <p>Si cela ne fonctionne toujours pas, contactez votre administrateur réseau..</p>
                        <p>
                            <strong>Status HTTP: {apiInformationError.error}</strong>
                        </p>
                        <hr />
                        <p className="mb-0">Cliquez sur l'encadré pour rafraîchir la page.</p>
                    </div>
                ))}
        </>
    );
}
