// Fichier pour getsion des heures de chaque jours de la semaine.

import React from 'react';
import { useEffect, useState } from 'react';
import { RequestDefaultErrorHandler, SendPOSTRequest } from '../utils/FetchUtil';
import handleExpiredSession from '../utils/Session';

export default function HourManagement() {
    const weekdays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

    const [heures, setHeures] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [apiHourData, setApiHourData] = useState({});
    const [apiHourError, setApiHourError] = useState(undefined);

    function hasExpiredError() {
        return !isLoading && apiHourData && apiHourError && apiHourData.tokenExpired;
    }

    function hasOtherError() {
        return !isLoading && apiHourError;
    }

    useEffect(() => {
        SendPOSTRequest(
            '/api/data/get_parametre',
            { type: 'heures/jour' },
            (res, data) => {
                setApiHourData(data);
                if (res.status !== 200) {
                    setApiHourError(data);
                } else {
                    setHeures(data.data);
                }
                setIsLoading(false);
            },
            (err) => {
                setApiHourError(err);
                setIsLoading(false);
            },
            RequestDefaultErrorHandler,
        );
    }, []);

    function submitHourChange(event) {
        event.preventDefault();
        SendPOSTRequest(
            '/api/data/put_parametre',
            { type: 'heures/jour', data: heures },
            (res, data) => {
                if (res.status === 200) {
                    window.location.reload();
                    window.scrollTo(0, 0);
                } else {
                    alert('Echec de la requête.. Statut HTTP -> ' + data.error);
                }
            },
            RequestDefaultErrorHandler,
        );
    }

    function handleHourChange(day) {
        return (event) => {
            let tempHeures = heures;
            let val = Number(event.target.value);
            if (!isNaN(val)) {
                tempHeures[day] = val;
                setHeures(tempHeures);
            }
        };
    }

    return (
        <div className="w-100">
            <div className="d-flex justify-content-center">
                {isLoading && (
                    <div className="position-fixed p-2 w-100">
                        <div className="alert alert-primary" role="alert">
                            Chargement des paramètres...
                            <br />
                            <div className="spinner-grow text-primary m-3" role="status">
                                <span className="visually-hidden">Chargement...</span>
                            </div>
                            <div className="spinner-grow text-light m-3" role="status">
                                <span className="visually-hidden">Chargement...</span>
                            </div>
                            <div className="spinner-grow text-danger m-3" role="status">
                                <span className="visually-hidden">Chargement...</span>
                            </div>
                        </div>
                    </div>
                )}

                {!isLoading && !apiHourError && (
                    <div className="container h-100">
                        <div className="d-flex-column h-100 justify-content-center align-items-center">
                            <div className="form-floating text-center mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                                <div className="d-flex form-check justify-content-center">
                                    <h1 className="mb-5 ms-5 me-5">Horaires Hebdomadaires</h1>
                                </div>

                                <form>
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-md-6 column table-responsive">
                                                <table className="table table-sm table-bordered table-hover table-light table-striped">
                                                    <thead>
                                                        <tr className="table-secondary">
                                                            <th scope="col">Jour</th>
                                                            <th scope="col">Nombre d'heures minimum</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {weekdays.map((day, idx) => (
                                                            <tr key={idx} className="align-middle align-center">
                                                                <th>{day}</th>
                                                                <td>
                                                                    <div className="form-floating">
                                                                        <input
                                                                            type="number"
                                                                            id="firstName"
                                                                            className="form-control"
                                                                            onChange={handleHourChange(day)}
                                                                            defaultValue={apiHourData.data[day]}
                                                                        />
                                                                        <label
                                                                            className="form-label"
                                                                            htmlFor="firstName"
                                                                        >
                                                                            Heures du {day.toLowerCase()}
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-success mt-3" type="button" onClick={submitHourChange}>
                                        Modifier
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                )}

                {(hasExpiredError() && handleExpiredSession()) ||
                    (hasOtherError() && (
                        <div
                            className="alert alert-danger mt-5 ms-5 me-5"
                            role="alert"
                            onClick={(_) => {
                                window.location.reload();
                            }}
                        >
                            <h4 className="alert-heading">Erreur!</h4>
                            <p>
                                Il semblerait qu'il y ait eu une erreur lors de la récupération des données sur le
                                serveur. Si cela continue après le rafraîchissement de la page, essayez de vous
                                déconnecter et de vous reconnecter.
                            </p>
                            <p>Si cela ne fonctionne toujours pas, contactez votre administrateur réseau..</p>
                            <p>
                                <strong>Description: </strong> {apiHourError.error}
                            </p>
                            <hr />
                            <p className="mb-0">Cliquez sur l'encadré pour rafraîchir la page.</p>
                        </div>
                    ))}
            </div>
        </div>
    );
}
