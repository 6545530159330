// Sous formulaire d'un chantier précis
// Avec possibilité d'ajouter et de supprimer un formulaire

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import TableActivity from './tableActivity';

export default function FormChantier(props) {
    const [communeSelectionee, setCommuneSelectionee] = useState(-1); // contiendra le code de la commune sélectionnée
    const [riviereSelectionee, setRiviereSelectionee] = useState(false);
    const [currentRiviere, setCurrentRiviere] = useState(null);

    function handleCommuneChange(event) {
        props.handleCommuneChange(event, props.cIdx);
        if (event.value) {
            setCommuneSelectionee(event.value);
        } else {
            setCommuneSelectionee(-1);
        }

        // quand la commune change, toutes les options doivent être effacées
        resetRiviereOptions(event.value);
        /*resetTronconOptions(null);
        setCurrentRiviere(null);
        setTronconPresetCode(null);*/
        resetTronconValue(props.cIdx);
        resetRiviereValue(props.cIdx);
    }

    function handleRiviereChange(event) {
        props.handleRiviereChange(event, props.cIdx);
        if (event.value) {
            setRiviereSelectionee(true);
            setCurrentRiviere(event.value);
        } else {
            setRiviereSelectionee(false);
        }
        setTronconPresetCode(null);
        resetTronconOptions(event.value);
    }

    function handleTronconChange(event) {
        props.handleTronconChange(event, props.cIdx);
        setTronconPresetCode(event.value);
    }

    function handleActivityChange(lstActivites) {
        props.handleActivityChange(lstActivites, props.cIdx);
    }

    /**
     * reset la valeur du select tronçon
     */
    function resetTronconValue(id) {
        let elt = document.getElementById('form_selectTroncon_' + id);
        if (elt) {
            elt.value = '';
        }
    }

    /**
     * reset la valeur du select riviere
     */
    function resetRiviereValue(id) {
        let elt = document.getElementById('form_selectRiviere_' + id);
        if (elt) {
            elt.value = '';
        }
    }

    //use of hook for couting

    const [communePresetCode, setCommunePresetCode] = useState(undefined);
    const [rivierePresetCode, setRivierePresetCode] = useState(undefined);
    const [tronconPresetCode, setTronconPresetCode] = useState(undefined);

    // communeOptions n'a pas besoin d'être dynamique
    const [tronconOptions, setTronconOptions] = useState([]);
    const [riviereOptions, setRiviereOptions] = useState([]);

    useEffect(() => {
        if (
            props.presetData &&
            props.presetData.chantiers[props.cIdx] &&
            props.presetData.chantiers[props.cIdx].commune &&
            props.presetData.chantiers[props.cIdx].riviere //&&
            //props.presetData.chantiers[props.cIdx].troncon
        ) {
            let commune = props.presetData.chantiers[props.cIdx].commune;
            let riviere = props.presetData.chantiers[props.cIdx].riviere;
            //let troncon = props.presetData.chantiers[props.cIdx].troncon;
            setCommunePresetCode(commune.code);
            setRivierePresetCode(riviere.code);
            setCurrentRiviere(riviere.code); // il faut aussi mettre à jour la rivière courante avec la valeur de la prédéfinie
            if (props.presetData.chantiers[props.cIdx].troncon) {
                let troncon = props.presetData.chantiers[props.cIdx].troncon;
                setTronconPresetCode(troncon.code);
            }
            //setTronconPresetCode(troncon.code);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

        // set des select options
        if (
            props.presetData &&
            props.presetData.chantiers[props.cIdx] &&
            props.presetData.chantiers[props.cIdx].commune
        ) {
            // si on a une commune prédéfinie, il ne faut afficher que les rivières correspondantes
            resetRiviereOptions(props.presetData.chantiers[props.cIdx].commune.code);
        } else {
            // cas par défaut (remplissage et non édition de fiche)
            setRiviereOptions(
                props.data.rivieres
                    .filter((riviere) => riviere.isArchived === false)
                    .map((riviere) => {
                        return {
                            id: riviere.id,
                            value: riviere.code,
                            label: riviere.information + ' (' + riviere.code + ')',
                        };
                    }),
            );
        }
        if (
            props.presetData &&
            props.presetData.chantiers[props.cIdx] &&
            props.presetData.chantiers[props.cIdx].riviere
        ) {
            setCommuneSelectionee(props.presetData.chantiers[props.cIdx].commune.code);
            setTronconOptions(extractionTroncons(rivieresAvecTroncon));
            //resetTronconOptions(props.presetData.chantiers[props.cIdx].riviere.code);
        } else {
            setTronconOptions(extractionTroncons(rivieresAvecTroncon));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // mettre les variables à mettre à jour après le UseEffect dans les crochets normalement ...

    const communeOptions = props.data.communes
        .filter((commune) => commune.isArchived === false)
        .map((commune) => {
            return {
                id: commune.id,
                value: commune.code,
                label: commune.information + ' (' + commune.code + ')',
            };
        });

    /**
     * Met à jour rivieresOptions suivant la commune choisie => seulement les rivières qui passent par la commune
     */
    function resetRiviereOptions(codeCommune) {
        let allCommunes = props.data.communes;
        let codesRiviere = [];
        for (let i = 0; i < allCommunes.length; i++) {
            if (codeCommune === allCommunes[i].code) {
                for (let j = 0; j < allCommunes[i].rivieres.length; j++) {
                    codesRiviere.push(allCommunes[i].rivieres[j].code);
                }
                break;
            }
        }
        let res = props.data.rivieres
            .filter((riviere) => riviere.isArchived === false && codesRiviere.includes(riviere.code))
            .map((riviere) => {
                return {
                    id: riviere.id,
                    value: riviere.code,
                    label: riviere.information + ' (' + riviere.code + ')',
                };
            });

        // Mise à jour du select des rivières
        setRiviereOptions(res);
    }

    /**
     * Met à jour tronconOptions suivant la rivière choisie et la commune courante => seulement les tronçons de la rivière && de la commune
     */
    function resetTronconOptions(codeRiviere) {
        let allCommunes = props.data.communes;
        let codesTronconCommune = []; // tous les codes des tronçons de la commune qu'on a sélectionné
        for (let i = 0; i < allCommunes.length; i++) {
            if (allCommunes[i].code === communeSelectionee) {
                for (let j = 0; j < allCommunes[i].troncons.length; j++) {
                    codesTronconCommune.push(allCommunes[i].troncons[j].code);
                }
            }
        }

        let allRivieres = props.data.rivieres;
        let codesTroncon = []; // tous les codes des tronçons de la rivière qu'on recherche
        for (let i = 0; i < allRivieres.length; i++) {
            if (codeRiviere === allRivieres[i].code) {
                for (let j = 0; j < allRivieres[i].troncons.length; j++) {
                    codesTroncon.push(allRivieres[i].troncons[j].code);
                }
                break;
            }
        }

        // intersection du tableau des codes tronçons de la rivière sélectionnée
        // et du tableau des codes tronçons de la commune sélectionnée
        var intersection = codesTronconCommune.filter((code) => codesTroncon.includes(code));

        let res = [];
        // récuperation des infos des tronçons de l'intersection dans les rivières avec tronçons
        for (let i = 0; i < rivieresAvecTroncon.length; i++) {
            let troncons = rivieresAvecTroncon[i].troncons;
            let codesTronconsParRiviere = []; // tous les codes des tronçons de la ième rivière
            for (let k = 0; k < troncons.length; k++) {
                codesTronconsParRiviere.push(troncons[k].code);
                if (intersection.includes(troncons[k].code)) {
                    res.push({
                        id: troncons[k].id,
                        value: troncons[k].code,
                        label: troncons[k].information + ' (' + troncons[k].code + ')',
                    });
                }
            }
            /*
            if (
                codesTroncon.includes(codesTronconsParRiviere[0]) &&
                codesTronconCommune.includes(codesTronconsParRiviere[0])
            ) {
                for (let j = 0; j < troncons.length; j++) {
                    res.push({
                        id: troncons[j].id,
                        value: troncons[j].code,
                        label: troncons[j].information + ' (' + troncons[j].code + ')',
                    });
                }
                break;
            }*/
        }

        // Mise à jour du select des tronçons
        setTronconOptions(res);
    }

    // récupère les rivières avec tronçons
    const rivieresAvecTroncon = props.data.rivieres.filter(
        (riviere) => riviere.isArchived === false && riviere.troncons.length > 0,
    );

    function extractionTroncons(rivieres) {
        // remplit un tableau avec chaque tronçons existant => A MODIFIER POUR N'AVOIR QUE LES TRONCONS DE LA RIVIERE CORRESPONDANTE
        let res = [];
        for (let i = 0; i < rivieres.length; i++) {
            let troncons = rivieres[i].troncons;
            for (let j = 0; j < troncons.length; j++) {
                res.push({
                    id: troncons[j].id,
                    value: troncons[j].code,
                    label: troncons[j].information + ' (' + troncons[j].code + ')',
                });
            }
        }
        return res;
    }

    //const tronconOptions = extractionTroncons(rivieresAvecTroncon);

    const defaultCommune = communeOptions.find((opt) => opt.value === communePresetCode);
    const defaultRiviere = riviereOptions.find((opt) => opt.value === rivierePresetCode);
    const defaultTroncon = tronconOptions.find((opt) => opt.value === tronconPresetCode);

    return (
        <div
            className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-3 rounded-5 shadow-lg"
            id={'chantier-' + (props.cIdx + 1)}
        >
            <h5 className="mb-3">Saisie du chantier n°{props.cIdx + 1}</h5>
            <div className="form-floating mb-3">
                <Select
                    placeholder="Choisissez la commune"
                    id="form_selectCommune"
                    onChange={handleCommuneChange}
                    required
                    options={communeOptions}
                    defaultValue={defaultCommune}
                    key={defaultCommune}
                />
            </div>

            <div className="form-floating mb-3">
                <Select
                    placeholder="Choisissez la rivière"
                    id={'form_selectRiviere_' + props.cIdx}
                    onChange={handleRiviereChange}
                    isDisabled={communeSelectionee < 0 && !props.presetData}
                    required
                    options={riviereOptions}
                    defaultValue={defaultRiviere}
                    key={defaultRiviere}
                />
            </div>
            {rivieresAvecTroncon.findIndex((riv) => riv.code === currentRiviere) >= 0 && (
                <div className="form-floating mb-3">
                    <Select
                        placeholder="Choisissez le tronçon"
                        id={'form_selectTroncon_' + props.cIdx}
                        onChange={handleTronconChange}
                        isDisabled={!riviereSelectionee && !props.presetData}
                        required
                        options={tronconOptions}
                        defaultValue={defaultTroncon}
                        key={defaultTroncon}
                    />
                </div>
            )}
            <TableActivity
                data={props.data.activites}
                presetData={
                    props.presetData &&
                    props.presetData.chantiers[props.cIdx] &&
                    props.presetData.chantiers[props.cIdx].activites
                }
                handleActivityChange={handleActivityChange}
            />
        </div>
    );
}
