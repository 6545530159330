import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import handleExpiredSession from '../utils/Session';
import RecapElem from '../components/recapElem';
import { SendPOSTRequest, SendGETRequest } from '../utils/FetchUtil';
import { convertHourGood } from '../components/attendanceForm';

export default function ConsultPage() {
    const constSheetID = useParams().id;

    const [, setIsLoadingSheet] = useState(true);
    const [apiSheetData, setApiSheetData] = useState({});
    const [apiSheetError, setApiSheetError] = useState(undefined);

    const [isLoading, setIsLoading] = useState(true);
    const [apiInformationData, setApiInformationData] = useState({});
    const [apiInformationError, setApiInformationError] = useState(undefined);

    function hasExpiredError() {
        return !isLoading && apiSheetData && apiSheetError && apiSheetData.tokenExpired;
    }

    function hasOtherError() {
        return !isLoading && apiSheetError;
    }

    useEffect(() => {
        SendPOSTRequest(
            '/api/data/get_sheet_by_id',
            { id: constSheetID },
            (res, data) => {
                setApiSheetData(data);
                if (res.status !== 200) {
                    setApiSheetError(data);
                }
                setIsLoadingSheet(false);
            },
            (err) => {
                setApiSheetError(err);
                setIsLoadingSheet(false);
            },
        );
    }, [constSheetID]);

    useEffect(() => {
        SendGETRequest('/api/data/get_all_information', setApiInformationData, setApiInformationError, setIsLoading);
    }, []);

    function getFormData() {
        apiSheetData.chantiers.forEach((chantier) => {
            chantier.activites.forEach((activite) => {
                activite.activite = activite;
            });
        });

        const lst402 = [];
        apiSheetData.chantiers402.forEach((ent402, idx) => {
            lst402.push({});
            ent402.list.forEach((ent, subIdx) => {
                lst402[idx][subIdx] = { location: ent, nbHeures: convertHourGood(ent.nbHeure) };
            });
        });

        return {
            date: apiSheetData.date.slice(0, 10),
            user: apiSheetData.user,
            vehicules: apiSheetData.vehicules,
            chantiers: apiSheetData.chantiers,
            lst402: lst402,
            attendance: apiSheetData.attendance,
        };
    }

    return (
        <div>
            {isLoading && (
                <div className="position-fixed p-2 w-100">
                    <div className="alert alert-primary" role="alert">
                        Chargement de la fiche...
                        <br />
                        <div className="spinner-grow text-primary m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                        <div className="spinner-grow text-light m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                        <div className="spinner-grow text-danger m-3" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && !apiSheetError && (
                // transmission des infos utilisateur au composant teamManagement.jsx
                <RecapElem
                    presetData={apiSheetData}
                    constSheetID={constSheetID}
                    sendRecapUpdater={() => {}}
                    data={apiInformationData}
                    getFormData={getFormData}
                    isConsulting={true}
                />
            )}

            {(hasExpiredError() && handleExpiredSession()) ||
                (hasOtherError() && (
                    <div
                        className="alert alert-danger mt-5 ms-5 me-5"
                        role="alert"
                        onClick={(_) => {
                            window.location.reload();
                        }}
                    >
                        <h4 className="alert-heading">Erreur!</h4>
                        <p>
                            Il semblerait qu'il y ait eu une erreur lors de la récupération des données sur le serveur.
                            Si cela continue après le rafraîchissement de la page, essayez de vous déconnecter et de
                            vous reconnecter.
                        </p>
                        <p>Si cela ne fonctionne toujours pas, contactez votre administrateur réseau..</p>
                        <p>
                            <strong>Description: </strong> {apiSheetError.error}
                        </p>
                        <hr />
                        {apiInformationError && (
                            <>
                                <p>
                                    <strong>Description: </strong> {apiInformationError.error}
                                </p>
                                <hr />
                            </>
                        )}
                        <p className="mb-0">Cliquez sur l'encadré pour rafraîchir la page.</p>
                    </div>
                ))}
        </div>
    );
}
