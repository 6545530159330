import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import bgImage from '../img/fond.jpg';
import { setAlert } from '../utils/AlertMessage';
import logo from '../img/logo-cisalbwhite.png';

const GlobalStyle = createGlobalStyle`
  body, html {
    height: 100%;
  }
`;

const Background = styled.div`
    background-image: url(${bgImage});
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

class ConnectionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'saisie',
            password: '',
            keepConnected: false,
            isFormDisabled: false,
        };

        this.state.isTokenVerified = false;

        this.handleModeChange = this.handleModeChange.bind(this);
        this.handlePassChange = this.handlePassChange.bind(this);
        this.handleKeepConnChange = this.handleKeepConnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        // Si le token est valide, on renvoie vers le '/home'
        let token = localStorage.getItem('token');
        let userMode = localStorage.getItem('userMode');

        if (token && userMode && (userMode === '1' || userMode === '2')) {
            fetch('/api/auth/verify_token', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        window.location.replace('/home');
                    } else {
                        localStorage.clear();
                        window.location.reload();
                    }
                    this.state.isTokenVerified = true;
                })
                .catch((err) => {
                    this.state.isTokenVerified = true;
                });
        } else {
            this.state.isTokenVerified = true;
        }
    }

    setErrorAlert(message, passError) {
        setAlert(message, 'danger', 'errorAlert');
        if (passError) {
            document.getElementById('password-connection').classList.add('is-invalid');
        }
    }

    handleModeChange(event) {
        this.setState({ mode: event.target.value });
    }

    handlePassChange(event) {
        this.setState({ password: event.target.value });
    }

    handleKeepConnChange(event) {
        this.setState({ keepConnected: event.target.checked });
    }

    handleSubmit(event) {
        this.setState({ isFormDisabled: true });
        event.preventDefault();

        fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mode: this.state.mode,
                password: this.state.password,
                keepConnected: this.state.keepConnected,
            }),
        })
            .then(async (res) => {
                let json_data = await res.json();
                this.setState({ isFormDisabled: false });
                if (res.status !== 200) {
                    this.setErrorAlert(json_data.error, true);
                } else {
                    localStorage.clear();
                    localStorage.setItem('userMode', json_data.userMode.toString());
                    localStorage.setItem('token', json_data.token);

                    // A enlever plus tard ...
                    fetch('/api/auth/verify_token', {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + json_data.token,
                        },
                    }).then((res) => {
                        if (res.status === 200) {
                            window.location.replace('/home');
                        } else {
                            this.setErrorAlert('Impossible de vérifier le token de connexion.', false);
                        }
                    });
                }
            })
            .catch((err) => {
                this.setState({ isFormDisabled: false });
                this.setErrorAlert("Erreur lors de l'envoi de la requête au serveur.", false);
                console.error(err);
            });
    }

    // Affichage de la page de connexion
    render() {
        return (
            (!this.state.isTokenVerified && (
                <div className="position-fixed p-2 w-100">
                    <div className="alert alert-primary" role="alert">
                        Vérification du token ...
                    </div>
                </div>
            )) ||
            (this.state.isTokenVerified && (
                <>
                    <div id="errorAlert" className="position-fixed p-2 w-100"></div>
                    <GlobalStyle />
                    <Background>
                        <div className="container h-100">
                            <div className="d-flex h-100 justify-content-center align-items-center">
                                <img className="w-25 m-5" src={logo} alt="Le logo du CISALB" />

                                <form
                                    onSubmit={this.handleSubmit}
                                    className="form-floating text-center w-50 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg"
                                >
                                    <fieldset disabled={this.state.isFormDisabled}>
                                        <h1 className="mb-5">Connexion - Outil de Remplissage de Fiches</h1>

                                        <div className="form-floating mb-3">
                                            <select
                                                className="form-select"
                                                id="floatingSelectSession"
                                                required
                                                defaultValue={this.state.mode}
                                                onChange={this.handleModeChange}
                                            >
                                                <option id="default_session" value="saisie">
                                                    Saisie
                                                </option>
                                                <option value="manager">Manager</option>
                                            </select>
                                            <label htmlFor="floatingSelectSession">Choisissez votre session</label>
                                        </div>

                                        <div className="form-floating mb-2">
                                            <input
                                                type="password"
                                                name="password-connection"
                                                id="password-connection"
                                                className="form-control"
                                                placeholder="Mot de passe"
                                                required
                                                value={this.state.password}
                                                onChange={this.handlePassChange}
                                            />
                                            <label htmlFor="password-connection">Mot de passe</label>
                                        </div>

                                        <div className="input-group d-flex justify-content-center">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={this.state.keepConnected}
                                                id="flexCheckDefault"
                                                onChange={this.handleKeepConnChange}
                                            />
                                            <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                Rester connecté
                                            </label>
                                        </div>

                                        <input type="submit" value="Se connecter" className="btn btn-primary mt-3" />
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </Background>
                </>
            ))
        );
    }
}

export default ConnectionPage;
