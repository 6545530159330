import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import FormChantier from './formChantier';
import Form402 from './form402';
import { convertHourGood } from './attendanceForm';
import { BsArrowLeftCircleFill } from 'react-icons/bs';

export default function InterventionForm(props) {
    const [vehicules, setVehicules] = useState({});
    const [chantiers, setChantiers] = useState([{}]);
    const [lst402, setLst402] = useState([]);

    function addChantierToArray(chantier) {
        let shallow = [...chantiers];
        shallow.push(chantier);
        setChantiers(shallow);
    }

    function delChantierFromArray() {
        let shallow = [...chantiers];
        let chantier = shallow.pop();
        setChantiers(shallow);
        return chantier;
    }

    function handleAddChantier(event) {
        addChantierToArray({});
    }

    function handleDelChantier(event) {
        delChantierFromArray();
    }

    function add402ToArray(elem) {
        let shallow = [...lst402];
        shallow.push(elem);
        setLst402(shallow);
    }

    function del402FromArray() {
        let shallow = [...lst402];
        let elem = shallow.pop();
        setLst402(shallow);
        return elem;
    }

    function handleAdd402(event) {
        add402ToArray({});
    }

    function handleDelete402(event) {
        del402FromArray();
    }

    function handleCommuneChange(event, idx) {
        let shallow = [...chantiers];
        shallow[idx].commune = {
            id: event.id,
            code: event.value,
            information: event.label,
        };
        setChantiers(shallow);
    }

    function handleRiviereChange(event, idx) {
        let shallow = [...chantiers];
        shallow[idx].riviere = {
            id: event.id,
            code: event.value,
            information: event.label,
        };
        setChantiers(shallow);
        delete shallow[idx].troncon; // lorsqu'on change de rivière, il faut supprimer le tronçon
    }

    function handleTronconChange(event, idx) {
        let shallow = [...chantiers];
        shallow[idx].troncon = {
            id: event.id,
            code: event.value,
            information: event.label,
        };
        setChantiers(shallow);
    }

    function handleVehiculeChange(event) {
        let v_id = event.target.id;
        let v_code = event.target.value;
        let v_nom = event.target.name;

        let shallowVehicules = { ...vehicules };

        if (!event.target.checked) {
            if (v_id in shallowVehicules) {
                delete shallowVehicules[v_id];
            } else {
                shallowVehicules[v_id] = {
                    id: v_id,
                    code: v_code,
                    information: v_nom,
                };
            }
        } else {
            if (!(v_id in shallowVehicules)) {
                shallowVehicules[v_id] = {
                    id: v_id,
                    code: v_code,
                    information: v_nom,
                };
            }
        }

        setVehicules(shallowVehicules);
    }

    function handle402HourChange(event, idx, ent, subListIdx) {
        let shallow = [...lst402];
        let val = parseFloat(event.target.value);

        // Si la valeur est 0 ou une chaine vide, on retire la grille de notre liste
        if (val === 0 || event.target.value === '') {
            if (shallow[idx][subListIdx]) {
                delete shallow[idx][subListIdx];
            }
        } else if (!isNaN(val)) {
            shallow[idx][subListIdx] = { location: ent, nbHeures: convertHourGood(val) };
        }
        setLst402(shallow);
    }

    function handleSubmit(event) {
        props.handleInterventionSubmit({
            vehicules: vehicules,
            chantiers: chantiers,
            lst402: lst402,
        });
        props.handleCarouselRedirection(false);
        props.handlePositionCarousel(3);
        event.preventDefault();
        /*
      const navigate = useNavigate();
      navigate('/home');*/
    }

    function handleActivityChange(lstActivites, idx) {
        let shallow = [...chantiers];
        shallow[idx].activites = lstActivites;
        setChantiers(shallow);
    }

    useEffect(() => {
        if (props.presetData) {
            // On mets les véhicules depuis les données 'preset'
            let shallowVehicules = { ...vehicules };
            props.presetData.vehicules.forEach((veh) => {
                shallowVehicules[veh.id] = veh;
            });
            setVehicules(shallowVehicules);

            // On crée les chantiers
            let tmpChantiers = [];
            props.presetData.chantiers.forEach((cha) => {
                let obj = {
                    riviere: cha.riviere,
                    commune: cha.commune,
                    troncon: cha.troncon,
                    activites: [],
                };

                cha.activites.forEach((act) => {
                    obj.activites.push({
                        activite: act,
                        nbHeure: convertHourGood(act.nbHeure),
                    });
                });

                tmpChantiers.push(obj);
                setChantiers(tmpChantiers);
            });

            // On ajoute les chantiers 402 si des données de 'preset' existent
            let tmp402 = [];
            props.presetData.chantiers402.forEach((cha402, idx) => {
                tmp402.push({});
                cha402.list.forEach((loc, subIdx) => {
                    tmp402[idx][subIdx] = { location: loc, nbHeures: convertHourGood(loc.nbHeure) };
                });
            });
            setLst402(tmp402);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleBack(event) {
        props.handlePositionCarousel(1);
        event.preventDefault();
    }

    // Affichage du formulaire de présence
    // le tableau est à afficher dynamiquement avec les membres du personnel

    return (
        <>
            <div className="container h-100">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <form className="form-floating text-center w-100 m-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                        <div className="d-flex form-check justify-content-center">
                            <button
                                value="Retour"
                                className="mt-3 h-25 border-0 bg-transparent"
                                data-bs-target="#carouselHome"
                                data-bs-slide="prev"
                                onClick={handleBack}
                                title="Retour en arrière"
                            >
                                <BsArrowLeftCircleFill size={29} className="text-danger" />
                            </button>
                            <h1 className="mb-3 ms-5 me-5">Formulaire d'enregistrement des chantiers</h1>
                        </div>

                        <div
                            className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-3 rounded-5 shadow-lg"
                            id="vehicules"
                        >
                            <h5 className="mb-3">Saisie des véhicules</h5>
                            {props.data.vehicules
                                .filter((vehicule) => vehicule.isArchived === false)
                                .map((vehicule) => {
                                    if (!vehicule.isArchived) {
                                        return (
                                            <div
                                                className="input-group mx-auto w-50 mb-3"
                                                key={vehicule.id}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <div className="input-group-text">
                                                    <input
                                                        className="form-check-input mt-0"
                                                        type="checkbox"
                                                        id={vehicule.id}
                                                        value={vehicule.code}
                                                        checked={vehicule.id in vehicules}
                                                        onChange={handleVehiculeChange}
                                                        name={vehicule.information + ' (' + vehicule.code + ')'}
                                                        aria-label="Checkbox"
                                                    />
                                                </div>
                                                <span
                                                    className="form-control input-group-text "
                                                    id={vehicule.id}
                                                    value={vehicule.code}
                                                    name={vehicule.information + ' (' + vehicule.code + ')'}
                                                    onClick={() =>
                                                        /*Les attributs name et value ne sont pas des propriétés standard de l’élément span. 
                                                        Par conséquent, ils ne sont pas transmis à la fonction handleVehiculeChange via l’objet d’événement.*/
                                                        handleVehiculeChange({
                                                            target: {
                                                                id: vehicule.id,
                                                                name: vehicule.information + ' (' + vehicule.code + ')',
                                                                value: vehicule.code,
                                                            },
                                                        })
                                                    }
                                                    aria-label="Text checkbox Camion grue"
                                                >
                                                    {vehicule.information + ' (' + vehicule.code + ')'}
                                                </span>
                                            </div>
                                        );
                                    } else {
                                        return false;
                                    }
                                })}
                        </div>

                        {chantiers.map((_, idx) => {
                            return (
                                <FormChantier
                                    presetData={props.presetData}
                                    key={idx}
                                    data={props.data}
                                    cIdx={idx}
                                    handleCommuneChange={handleCommuneChange}
                                    handleRiviereChange={handleRiviereChange}
                                    handleActivityChange={handleActivityChange}
                                    handleTronconChange={handleTronconChange}
                                />
                            );
                        })}
                        {lst402.map((ent402, idx) => {
                            return (
                                <Form402
                                    data402={props.data402}
                                    fIdx={idx}
                                    key={idx}
                                    handle402HourChange={handle402HourChange}
                                    presetData={props.presetData}
                                    data={ent402} // On passe notre objet au 'Form402' pour qu'il sache s'il s'agit d'une entrée crée manuellement ou prédéfinie (mode édition)
                                />
                            );
                        })}

                        <div className="d-flex-column">
                            <div className="mb-3">
                                <div className="d-inline-flex">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="outline-danger" id="dropdown-basic">
                                            Supprimer
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                disabled={chantiers.length === 1}
                                                onClick={handleDelChantier}
                                                href="#"
                                            >
                                                Supprimer chantier
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                                disabled={lst402.length === 0}
                                                onClick={handleDelete402}
                                                href="#"
                                            >
                                                Supprimer formulaire 402
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown className="ms-3">
                                        <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
                                            Nouveau
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleAddChantier} href="#">
                                                Nouveau chantier
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={handleAdd402} href="#">
                                                Nouveau formulaire 402
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <input
                                type="submit"
                                value="Suivant"
                                onClick={handleSubmit}
                                className="btn btn-primary mt-3"
                                data-bs-target="#carouselHome"
                                data-bs-slide="next"
                            />
                        </div>
                    </form>
                </div>
            </div>
            <Dropdown className="ms-3 fixed-bottom ">
                <Dropdown.Toggle
                    className="position-absolute bottom-50 start-0 mb-2"
                    variant="primary"
                    id="dropdown-basic"
                >
                    Parcourir
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#vehicules">Véhicules</Dropdown.Item>
                    <Dropdown.Divider />
                    {chantiers.map((ref, idx) => {
                        return (
                            <Dropdown.Item key={idx} href={'#chantier-' + (idx + 1)}>
                                {' '}
                                Chantier n°{idx + 1}
                            </Dropdown.Item>
                        );
                    })}

                    <Dropdown.Divider />
                    {lst402.map((ref, idx) => {
                        return (
                            <Dropdown.Item key={idx} href={'#402-' + (idx + 1)}>
                                {' '}
                                Code 402 n°{idx + 1}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}
