import React, { useEffect, useState } from 'react';
import { setAlert, clearAlert } from '../utils/AlertMessage';

import { BsArrowLeftCircleFill } from 'react-icons/bs';

const ListeTaches = [
    'Travail',
    'Conge',
    'CongeExceptionnel',
    'Formation',
    'AccidentDuTravail',
    'Maladie',
    'VisiteMedical',
    'Stagiaire',
];

const ListeJours = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

const maxTempsTravail = 24;

export const convertHourGood = (hour) => {
    let res = parseFloat(hour+"");
    if (isNaN(res)) {
        res = 0;
    }
    return res

}

function AttendanceRow({ onChange, onNameChange, user, isTotal, totalRefList }) {
    return (
        <tr className={user.totalMismatch ? 'table-danger' : ''}>
            <th>
                {(!user.isStagiaire && user.fullName) || (
                    <input
                        type="text"
                        className="align-middle text-center w-75"
                        defaultValue={user.fullName}
                        onChange={(ev) => {
                            onNameChange && onNameChange({ id: user.userId, fullName: ev.target.value });
                        }}
                    />
                )}
            </th>
            {ListeTaches.map((tache) => {
                return (
                    <td key={'input_row_' + tache}>
                        <input
                            type="number"
                            className="align-middle text-center w-75"
                            defaultValue={isTotal ? '0' : user[tache] !== 0 ? convertHourGood(user[tache]) : ''}
                            min={0}
                            onChange={
                                onChange &&
                                onChange(
                                    { id: user.userId, fullName: user.fullName, isStagiaire: user.isStagiaire },
                                    tache,
                                )
                            }
                            required
                            disabled={isTotal}
                            ref={(isTotal && totalRefList[tache]) || null}
                        />
                    </td>
                );
            })}
            <td>
                <input type="number" className="align-middle text-center w-75" value={convertHourGood(user.Total)} disabled={true} />
            </td>
        </tr>
    );
}

class UtilisateurTableau {
    constructor(userId, fullName, isStagiaire) {
        this.userId = userId;
        this.fullName = fullName;
        this.isStagiaire = isStagiaire;

        ListeTaches.forEach((tache) => (this[tache] = 0));

        this.Total = 0;
        this.totalMismatch = false;
    }

    static getTotal(listeUtilisateur, type) {
        return listeUtilisateur.reduce((prev, user) => {
            return prev + user[type];
        }, 0);
    }

    static findById(listeUtilisateur, id) {
        let res = null;
        listeUtilisateur.every((user) => {
            if (user.userId === id) {
                res = user;
                return false;
            }
            return true;
        });
        return res;
    }

    static countStagiaires(listeUtilisateur) {
        return listeUtilisateur.reduce((prev, user) => {
            if (user.isStagiaire) {
                return prev + 1;
            } else {
                return prev;
            }
        }, 0);
    }

    checkMismatch(maxValue) {
        this.totalMismatch = this.Total < maxValue || this.Total > maxTempsTravail;
    }

    recalculateTotal() {
        this.Total = ListeTaches.reduce((prev, tache) => {
            return prev + this[tache];
        }, 0);
    }

    setName(name) {
        this.fullName = name;
    }

    setValue(name, value) {
        this[name] = value;
        this.recalculateTotal();
    }
}

export default function AttendanceForm(props) {
    // Juste pour re-render plus simplement
    const [stateStaleVar, setStateStaleVar] = useState(false);
    const [listUsers] = useState(
        props.data
            .filter((user) => {
                return user.isArchived === false && user.mode !== 1;
            })
            .map((user) => {
                return new UtilisateurTableau(user.id, user.fullName, false);
            }),
    );

    const totalList = {};
    ListeTaches.forEach((tache) => {
        totalList[tache] = React.createRef();
    });

    function updateTotals(type) {
        totalList[type].current.value = UtilisateurTableau.getTotal(listUsers, type);
    }

    function updateAllTotals() {
        ListeTaches.forEach((tache) => {
            updateTotals(tache);
        });
    }

    var formDate = props.getFormDate();
    var maxHourForDay = formDate && props.hourData[ListeJours[new Date(formDate).getDay()]];

    function refetchMaxHour() {
        formDate = props.getFormDate();
        maxHourForDay = formDate && props.hourData[ListeJours[new Date(formDate).getDay()]];
    }

    function setAlertIfMismatch(setMismatchForUser = null) {
        refetchMaxHour();
        let count = 0;
        listUsers.every((user) => {
            if (user['Stagiaire'] > 0) {
                user.totalMismatch = false;
                return true;
            }

            // Check mismatch for all users, or for one specific user
            if (!setMismatchForUser) {
                user.checkMismatch(maxHourForDay);
            } else if (user.userId === setMismatchForUser.userId) {
                user.checkMismatch(maxHourForDay);
            }

            if (user.totalMismatch) {
                setAlert(
                    `Chaque personne doit avoir au moins ${maxHourForDay} heure` +
                        (maxHourForDay > 1 ? 's' : '') +
                        " d'inscrite" +
                        (maxHourForDay > 1 ? 's' : '') +
                        `  et au maximum ${maxTempsTravail} heures pour cette journée!`,
                    'danger',
                    'errorInputAlert',
                );

                // Update 'totalMismatch' for all users, or for one specific user
                if (!setMismatchForUser) {
                    user.totalMismatch = true;
                } else if (user.userId === setMismatchForUser.userId) {
                    user.totalMismatch = true;
                }
                count++;
            } else {
                // Update 'totalMismatch' for all users, or for one specific user
                if (!setMismatchForUser) {
                    user.totalMismatch = false;
                } else if (user.userId === setMismatchForUser.userId) {
                    user.totalMismatch = false;
                }
            }
            return true;
        });
        if (count === 0) {
            clearAlert('errorInputAlert');
        }
        setStateStaleVar(!stateStaleVar);
        return count > 0;
    }

    function handleTableRowChange(data, type) {
        return (e) => {
            refetchMaxHour();
            let val = parseFloat(e.target.value);
            if (isNaN(val)) {
                val = parseFloat('0');
            }

            if (!isNaN(val)) {
                let user = UtilisateurTableau.findById(listUsers, data.id);
                user.setValue(type, val);
                updateTotals(type);
                setStateStaleVar(!stateStaleVar);
                setAlertIfMismatch(user);
            }
        };
    }

    function handleStagiaireNameChange(data) {
        UtilisateurTableau.findById(listUsers, data.id).setName(data.fullName);
    }

    const suppButtonRef = React.createRef();
    function incrementNoRows() {
        suppButtonRef.current.disabled = false;

        let numStagiaire = UtilisateurTableau.countStagiaires(listUsers);
        listUsers.push(new UtilisateurTableau('temp' + numStagiaire, '', true));
        setStateStaleVar(!stateStaleVar);
    }

    function decrementNoRows() {
        UtilisateurTableau.countStagiaires(listUsers) === 1
            ? (suppButtonRef.current.disabled = true)
            : (suppButtonRef.current.disabled = false);
        listUsers.pop();
        updateAllTotals();
        setStateStaleVar(!stateStaleVar);
    }

    useEffect(() => {
        if (props.presetData) {
            ListeTaches.forEach((tache) => {
                props.presetData.attendance[tache].forEach((user) => {
                    let matchedUser = UtilisateurTableau.findById(listUsers, user.id);
                    let nbHeure = convertHourGood(user.nbHeure);
                    if (!matchedUser) {
                        matchedUser = new UtilisateurTableau(user.id, user.fullName, true);
                        listUsers.push(matchedUser);
                    }
                    matchedUser.setValue(tache, nbHeure);
                    setStateStaleVar(!stateStaleVar);
                    refetchMaxHour();
                    matchedUser.checkMismatch(maxHourForDay);
                });
            });
            updateAllTotals();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSubmit(event) {
        // Transformation de notre format dans l'ancien format:
        let totals = {};
        let attendance = {};
        ListeTaches.forEach((tache) => {
            totals[tache] = UtilisateurTableau.getTotal(listUsers, tache);
            attendance[tache] = { users: [] };
            listUsers.forEach((user) => {
                attendance[tache].users.push({ id: user.userId, fullName: user.fullName, nbHeure: user[tache] });
            });
        });
        props.handleCarouselRedirection(false);
        props.handleAttendanceSubmit(attendance, totals);
    }

    function handleBack(event) {
        props.handlePositionCarousel(0);
    }

    document.addEventListener('slide.bs.carousel', (event) => {
        if (event.from === 1 && event.relatedTarget.id === 'carouselInterventionForm') {
            if (setAlertIfMismatch()) {
                event.preventDefault();
            } else {
                props.handlePositionCarousel(2);
            }
        }
    });

    const TotalUser = new UtilisateurTableau('TOTAL', 'TOTAL', false);
    TotalUser.Total = listUsers.reduce((prev, user) => prev + user.Total, 0);

    // Affichage du formulaire de présence
    // le tableau est à afficher dynamiquement avec les membres du personnel
    return (
        <div className="container h-100">
            <div className="d-flex h-100 justify-content-center align-items-center">
                <form className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                    <div className="d-flex form-check justify-content-center">
                        <button
                            value="Retour"
                            className="mt-3 h-25 border-0 bg-transparent"
                            data-bs-target="#carouselHome"
                            data-bs-slide="prev"
                            onClick={handleBack}
                            title="Retour en arrière"
                        >
                            <BsArrowLeftCircleFill size={29} className="text-danger" />
                        </button>
                        <h1 className="mb-5 ms-5 me-5">Formulaire d'enregistrement du temps de travail</h1>
                    </div>

                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-md-12 column table-responsive">
                                <table className="table tabel-sm table-bordered table-hover table-light table-striped">
                                    <thead>
                                        <tr className="table-secondary align-middle">
                                            <th className="w-25" scope="col">
                                                Personnel
                                            </th>
                                            <th scope="col" className="d-none">
                                                Stagiaire (900)
                                            </th>
                                            <th scope="col">Travail (900)</th>
                                            <th scope="col">Congé (802)</th>
                                            <th scope="col">Congé exceptionnel (803)</th>
                                            <th scope="col">Formation (804)</th>
                                            <th scope="col">Accident du travail (805)</th>
                                            <th scope="col">Maladie (806)</th>
                                            <th scope="col">Visite médicale (809)</th>
                                            <th scope="col">Heures Stagiaire (902)</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listUsers.map((user, idx) => {
                                            return (
                                                <AttendanceRow
                                                    key={'row_user_' + idx}
                                                    onChange={handleTableRowChange}
                                                    onNameChange={handleStagiaireNameChange}
                                                    user={user}
                                                    maxValueForDay={maxHourForDay}
                                                ></AttendanceRow>
                                            );
                                        })}
                                        <AttendanceRow
                                            key={'row_user_total'}
                                            user={TotalUser}
                                            isTotal={true}
                                            totalRefList={totalList}
                                        ></AttendanceRow>
                                    </tbody>
                                </table>

                                <button
                                    type="button"
                                    ref={suppButtonRef}
                                    className="btn btn-outline-danger"
                                    onClick={decrementNoRows}
                                    disabled={UtilisateurTableau.countStagiaires(listUsers) < 1}
                                >
                                    Supprimer ligne
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-success ms-5"
                                    onClick={incrementNoRows}
                                >
                                    Ajouter ligne
                                </button>
                            </div>
                        </div>
                        <div id="errorInputAlert" className="mt-3"></div>
                    </div>
                    <input
                        type="submit"
                        value="Suivant"
                        className="btn btn-primary mt-3"
                        onClick={handleSubmit}
                        data-bs-target="#carouselHome"
                        data-bs-slide="next"
                    />
                </form>
            </div>
        </div>
    );
}
