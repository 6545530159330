import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Image } from 'react-bootstrap';
import logoAdmin from '../img/logoAdmin.png';
import logoSaisie from '../img/logoSaisie.png';

import { BsBoxArrowRight } from 'react-icons/bs';

export default function NavigationBar() {
    const userMode = localStorage.getItem('userMode');
    return (
        <div>
            <Navbar
                expand="lg"
                //className="shadow-sm"
                style={{
                    borderBottom: '1px solid #e2edff',
                    backgroundColor: '#e2edff',
                }}
            >
                <Navbar.Brand>
                    <div className="d-flex gap-2 align-items-center ms-3">
                        <div className="border rounded-circle p-1 shadow-sm">
                            {userMode === '1' && <Image src={logoAdmin} style={{ width: '35px', height: '35px' }} />}
                            {userMode === '2' && <Image src={logoSaisie} style={{ width: '35px', height: '35px' }} />}
                        </div>
                        <div className="d-flex flex-column">
                            <span className="text-muted fs-6">Session</span>
                            {userMode === '1' && <span className="fw-bold fs-6">Manager</span>}
                            {userMode === '2' && <span className="fw-bold fs-6">Saisie</span>}
                        </div>
                    </div>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between me-3 ms-3">
                    <Nav className="ms-3">
                        {/*<NavLink to="/" activeclassname="active nav-link me-5" className="nav-link me-5">
                            Login(Dev)
                        </NavLink>*/}
                        <NavLink
                            to="/home"
                            className={({ isActive }) => (isActive ? 'active nav-link me-5' : 'nav-link me-5')}
                            style={{ fontWeight: 'bold' }}
                        >
                            Accueil
                        </NavLink>
                        <NavLink
                            to="/recherche"
                            className={({ isActive }) => (isActive ? 'active nav-link me-5' : 'nav-link me-5')}
                            style={{ fontWeight: 'bold' }}
                        >
                            Recherche
                        </NavLink>
                        {/*userMode === '1' && (
                            <NavLink
                                to="/stats"
                                className={({ isActive }) => (isActive ? 'active nav-link me-5' : 'nav-link me-5')}
                                style={{ fontWeight: 'bold' }}
                            >
                                Statistiques
                            </NavLink>
                        )*/}
                        {userMode === '1' && (
                            <NavLink
                                to="/admin"
                                className={({ isActive }) => (isActive ? 'active nav-link me-5' : 'nav-link me-5')}
                                style={{ fontWeight: 'bold' }}
                            >
                                Administration
                                <span className="d-none ms-2 position-absolute top-10 start-90 translate-middle badge border border-light rounded-circle bg-danger p-2">
                                    <span className="visually-hidden">unread messages</span>
                                </span>
                            </NavLink>
                        )}
                    </Nav>
                    <Nav>
                        <NavLink
                            to="/"
                            align="end"
                            className="p-2"
                            title="Se déconnecter"
                            onClick={(_) => {
                                localStorage.clear(); // Deconnexion
                            }}
                        >
                            <BsBoxArrowRight size={30} className="text-primary" />
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}
