import React from 'react';

export default function NotFound() {
    return (
        <div className="position-fixed p-2 w-100">
            <div className="alert alert-danger" role="alert">
                <h1>Cette page n'existe pas.</h1>
            </div>
        </div>
    );
}
