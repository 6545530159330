import React, { useState } from 'react';
import { RequestDefaultErrorHandler, SendPOSTRequest } from '../utils/FetchUtil';
import Select from 'react-select';
import { BsTrash3, BsFillCaretDownFill } from 'react-icons/bs';

export default function DependanceManagement(props) {
    // récupère les rivières avec tronçons
    const rivieresAvecTroncon = props.data.rivieres.filter(
        (riviere) => riviere.isArchived === false && riviere.troncons.length > 0,
    );
    const communeOptions = props.data.communes
        .filter((commune) => commune.isArchived === false)
        .map((commune) => {
            return {
                id: commune.id,
                value: commune.code,
                label: commune.information + ' (' + commune.code + ')',
            };
        });
    const riviereOptions = props.data.rivieres
        .filter((riviere) => riviere.isArchived === false)
        .map((riviere) => {
            return {
                id: riviere.id,
                value: riviere.code,
                label: riviere.information + ' (' + riviere.code + ')',
            };
        });

    const [entryCode, setEntryCode] = useState(undefined);
    const [entryInfo, setEntryInfo] = useState('');
    const [entryCommune, setEntryCommune] = useState([]);
    const [entryCommune2, setEntryCommune2] = useState([]); // le deuxième select commune pour les tronçons
    const [entryRiviere, setEntryRiviere] = useState([]);
    const [entryRiviere2, setEntryRiviere2] = useState([]); // le deuxième select rivière pour les tronçons
    const [entryTroncon, setEntryTroncon] = useState([]); // correspond au select des tronçons dépendance commune / tronçon

    /**
     * Par défaut, les tronçons sont stockés dans les rivières
     */
    function extractionTroncons(rivieres) {
        let res = [];
        for (let i = 0; i < rivieres.length; i++) {
            let troncons = rivieres[i].troncons;
            for (let j = 0; j < troncons.length; j++) {
                res.push({
                    id: troncons[j].information,
                    value: troncons[j].code,
                    label: troncons[j].information + ' (' + troncons[j].code + ')',
                });
            }
        }
        return res;
    }

    const tronconOptions = extractionTroncons(rivieresAvecTroncon).sort(function (a, b) {
        return a.value - b.value; // trie par code croissant
    });

    function handleCommuneChange(event) {
        setEntryCommune([event.id, event.value, event.label]);
        // [id, code, label (info + code)]
    }

    // le 2 correspond au select de la commune dépendance commune / tronçon
    function handleCommune2Change(event) {
        setEntryCommune2([event.id, event.value, event.label]);
    }

    function handleRiviereChange(event) {
        setEntryRiviere([event.id, event.value, event.label]);
    }

    // le 2 correspond au select de la rivière dépendance rivière / tronçon
    function handleRiviere2Change(event) {
        setEntryRiviere2([event.id, event.value, event.label]);
    }

    // correspond au select des tronçons dépendance commune / tronçon
    function handleTronconChange(event) {
        setEntryTroncon([event.id, event.value, event.label]);
    }

    function handleTronconAdd(idRiviere, code, information) {
        //event.preventDefault();
        SendPOSTRequest(
            '/api/data/add_troncon_to_rivere',
            {
                id: idRiviere,
                code: parseInt(code),
                information: information,
                table: 'riviere',
            },
            (res, data) => {
                if (res.status === 200) {
                    alert("Succès de l'ajout de l'élément");
                    window.location.reload();
                    window.scrollTo(0, 0);
                } else {
                    alert('Echec de la requête.. Statut HTTP ->' + res.status.toString());
                }
            },
            RequestDefaultErrorHandler,
        );
    }

    /**
     * Ajout d'un tronçon sur une commune
     * @param {*} idCommune ID de la commune
     * @param {*} code code du tronçon
     * @param {*} information nom du tronçon
     */
    function handleTronconToCommuneAdd(idCommune, code, information) {
        //event.preventDefault();
        SendPOSTRequest(
            '/api/data/add_troncon_to_commune',
            {
                id: idCommune,
                code: parseInt(code),
                information: information,
                table: 'commune',
            },
            (res, data) => {
                if (res.status === 200) {
                    alert("Succès de l'ajout de l'élément");
                    window.location.reload();
                    window.scrollTo(0, 0);
                } else {
                    alert('Echec de la requête.. Statut HTTP ->' + res.status.toString());
                }
            },
            RequestDefaultErrorHandler,
        );
    }

    function handleRiviereAdd(idCommune, idRiviere, code, information) {
        //event.preventDefault();
        SendPOSTRequest(
            '/api/data/add_riviere_to_commune',
            {
                id: idCommune,
                idRiviere: idRiviere,
                code: parseInt(code),
                information: information,
                table: 'commune',
            },
            (res, data) => {
                if (res.status === 200) {
                    alert("Succès de l'ajout de l'élément");
                    window.location.reload();
                    window.scrollTo(0, 0);
                } else {
                    alert('Echec de la requête.. Statut HTTP ->' + res.status.toString());
                }
            },
            RequestDefaultErrorHandler,
        );
    }

    /**
     * Supprime un tronçon d'une rivière (NB : les tronçons n'existent que dans les rivières ou communes,
     * ils n'ont pas de collection associée )
     */
    function handleTronconDelete(id, code, name) {
        return (event) => {
            if (window.confirm('Voulez-vous vraiment supprimer définitivement ' + name + ' de cette rivière ?')) {
                event.preventDefault();
                SendPOSTRequest(
                    '/api/data/delete_troncon_of_rivere',
                    { id: id, code: code, information: name, table: 'riviere' },
                    (res, data) => {
                        if (res.status === 200) {
                            alert("Succès de la suppression de l'élément");
                            window.location.reload();
                            window.scrollTo(0, 0);
                        } else {
                            alert('Echec de la requête.. Statut HTTP -> ' + res.status.toString());
                        }
                    },
                    RequestDefaultErrorHandler,
                );
            }
        };
    }

    /**
     * Supprime un tronçon d'une commune (NB : les tronçons n'existent que dans les rivières ou communes,
     * ils n'ont pas de collection associée )
     */
    function handleTronconCommuneDelete(id, code, name) {
        return (event) => {
            if (window.confirm('Voulez-vous vraiment supprimer ' + name + ' de cette commune ?')) {
                event.preventDefault();
                SendPOSTRequest(
                    '/api/data/delete_troncon_of_commune',
                    { id: id, code: code, information: name, table: 'commune' },
                    (res, data) => {
                        if (res.status === 200) {
                            alert("Succès de la suppression de l'élément");
                            window.location.reload();
                            window.scrollTo(0, 0);
                        } else {
                            alert('Echec de la requête.. Statut HTTP -> ' + res.status.toString());
                        }
                    },
                    RequestDefaultErrorHandler,
                );
            }
        };
    }

    function handleRiviereDelete(idCommune, idRiviere, code, name) {
        return (event) => {
            if (window.confirm('Voulez-vous vraiment supprimer ' + name + ' de cette commune ?')) {
                event.preventDefault();
                SendPOSTRequest(
                    '/api/data/delete_rivere_of_commune',
                    { id: idCommune, idRiviere: idRiviere, code: code, information: name, table: 'commune' },
                    (res, data) => {
                        if (res.status === 200) {
                            alert("Succès de la suppression de l'élément");
                            window.location.reload();
                            window.scrollTo(0, 0);
                        } else {
                            alert('Echec de la requête.. Statut HTTP -> ' + res.status.toString());
                        }
                    },
                    RequestDefaultErrorHandler,
                );
            }
        };
    }

    return (
        <div className="container h-100">
            <div className="d-flex-column h-100 justify-content-center align-items-center">
                <div className="form-floating text-center w-100 mt-5 mb-5 bg-light bg-opacity-50 p-5 rounded-5 shadow-lg">
                    <div className="d-flex form-check justify-content-center">
                        <h1 className="mb-5 ms-5 me-5">Édition des liens</h1>
                    </div>

                    {/*formulaire pour ajouter une dépendance entre une rivière et une commune*/}
                    <div className="d-flex form-check justify-content-center">
                        <h2 className="mb-5 ms-5 me-5">Ajouter une dépendance commune / rivière</h2>
                    </div>
                    <form className="mb-3">
                        <div className="row">
                            <div className="col mb-3">
                                <div className="form-floating">
                                    <Select
                                        placeholder="Choisissez la commune"
                                        id="form_selectCommune"
                                        onChange={handleCommuneChange}
                                        required
                                        options={communeOptions}
                                    />
                                </div>
                            </div>
                            <div className="col mb-3">
                                <div className="form-floating">
                                    <Select
                                        placeholder="Choisissez la rivière"
                                        id={'form_selectRiviere'}
                                        onChange={handleRiviereChange}
                                        required
                                        options={riviereOptions}
                                    />
                                </div>
                            </div>
                        </div>

                        <input
                            type="button"
                            onClick={() => {
                                entryRiviere[2] = entryRiviere[2].split(' ')[0]; // enlève le code entre parenthèses
                                handleRiviereAdd(entryCommune[0], entryRiviere[0], entryRiviere[1], entryRiviere[2]);
                            }}
                            className="btn btn-success mt-3"
                            value="Ajouter"
                        />
                    </form>

                    <hr className="hr my-5" />

                    {/*formulaire pour ajouter une dépendance entre un tronçon et une rivière*/}
                    <div className="d-flex form-check justify-content-center">
                        <h2 className="mb-5 ms-5 me-5">Ajouter une dépendance rivière / tronçon (création tronçon)</h2>
                    </div>
                    <form className="mb-5">
                        <div className="row">
                            <div className="form-floating mb-3">
                                <Select
                                    placeholder="Choisissez la rivière"
                                    id={'form_selectRiviere'}
                                    onChange={handleRiviere2Change}
                                    required
                                    options={riviereOptions}
                                />
                            </div>
                            <div className="col-md-2 mb-3">
                                <div className="form-floating">
                                    <input
                                        required
                                        type="number"
                                        id="activityCode"
                                        className="form-control"
                                        placeholder="Code du tronçon"
                                        defaultValue={entryCode}
                                        onChange={(event) => setEntryCode(event.target.value)}
                                    />
                                    <label className="form-label" htmlFor="activityCode">
                                        Code du tronçon
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-10 mb-3">
                                <div className="form-floating">
                                    <input
                                        required
                                        type="text"
                                        id="activityDescription"
                                        className="form-control"
                                        placeholder="Description du tronçon"
                                        defaultValue={entryInfo}
                                        onChange={(event) => setEntryInfo(event.target.value)}
                                    />
                                    <label className="form-label" htmlFor="activityDescription">
                                        Description du tronçon
                                    </label>
                                </div>
                            </div>
                        </div>

                        <input
                            onClick={() => {
                                handleTronconAdd(entryRiviere2[0], entryCode, entryInfo);
                            }}
                            className="btn btn-success mt-3"
                            type="button"
                            value="Ajouter"
                        />
                    </form>

                    <hr className="hr my-5" />

                    {/*formulaire pour ajouter une dépendance entre un tronçon et une commune*/}
                    <div className="d-flex form-check justify-content-center">
                        <h2 className="mb-5 ms-5 me-5">Ajouter une dépendance commune / tronçon</h2>
                    </div>
                    <form className="mb-5">
                        <div className="row">
                            <div className="col mb-3">
                                <div className="form-floating">
                                    <Select
                                        placeholder="Choisissez la commune"
                                        id={'form_selectCommune2'}
                                        onChange={handleCommune2Change}
                                        required
                                        options={communeOptions}
                                    />
                                </div>
                            </div>
                            <div className="col mb-3">
                                <div className="form-floating">
                                    <Select
                                        placeholder="Choisissez un tronçon"
                                        id={'form_selectTroncon'}
                                        onChange={handleTronconChange}
                                        required
                                        options={tronconOptions}
                                    />
                                </div>
                            </div>
                        </div>

                        <input
                            type="button"
                            onClick={() => {
                                handleTronconToCommuneAdd(entryCommune2[0], entryTroncon[1], entryTroncon[0]);
                            }}
                            className="btn btn-success mt-3"
                            value="Ajouter"
                        />
                    </form>

                    <hr className="hr my-5" />

                    <div className="container">
                        <p className="fst-italic text-muted">Cliquez sur une commune pour dérouler</p>
                        {props.data.communes.map((commune, idxCom) => {
                            // Pour cheque commune, on récupère ses rivières puis on regarde si elles ont des tronçons ou non
                            return (
                                <div className="card m-3" key={idxCom}>
                                    <div className="card-body">
                                        <h5
                                            className="card-title"
                                            key={commune.code}
                                            data-bs-toggle="collapse"
                                            title="Cliquer pour dérouler"
                                            style={{ cursor: 'pointer' }}
                                            href={'#collapse' + commune.id}
                                        >
                                            {commune.information + ' (' + commune.code + ')'} <BsFillCaretDownFill />
                                        </h5>
                                        <div className="collapse" id={'collapse' + commune.id}>
                                            <p className="card-text fst-italic">
                                                Voici la liste des rivières présentes sur {commune.information} :
                                            </p>

                                            {/*Affichage des boutons rivieres*/}

                                            {commune.rivieres.map((riviereCom, idxRiv) => {
                                                const riviere = props.data.rivieres.find(
                                                    (riviere) => riviere.code === riviereCom.code,
                                                );
                                                // Vérifie si la rivière donnée possède des tronçons
                                                // Si c'est le cas, renvoie un DropdownButton avec ses tronçons
                                                if (rivieresAvecTroncon.includes(riviere)) {
                                                    return (
                                                        <div className="dropdown" key={idxRiv}>
                                                            <button
                                                                className="btn btn-secondary opacity-75 dropdown-toggle my-1 py-2"
                                                                type="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                key={riviereCom.code}
                                                            >
                                                                {riviereCom.information + ' (' + riviereCom.code + ')'}
                                                            </button>
                                                            <button
                                                                value="Delete"
                                                                className="h-25 border-0 bg-transparent"
                                                                title={
                                                                    'Supprimer ' +
                                                                    riviere.information +
                                                                    ' de la commune ' +
                                                                    commune.information
                                                                }
                                                                onClick={handleRiviereDelete(
                                                                    commune.id,
                                                                    riviere.id,
                                                                    riviere.code,
                                                                    riviere.information,
                                                                )}
                                                            >
                                                                <BsTrash3 className="text-danger" size={20} />
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <div className="container text-center">
                                                                    {riviere.troncons.map((troncon, idx3) => {
                                                                        return (
                                                                            <div className="row" key={idx3}>
                                                                                <div className="col">
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href=" "
                                                                                    >
                                                                                        {troncon.information +
                                                                                            ' (' +
                                                                                            troncon.code +
                                                                                            ')'}
                                                                                    </a>
                                                                                </div>
                                                                                <div className="col-md-auto">
                                                                                    <button
                                                                                        value="Delete"
                                                                                        className="h-25 border-0 bg-transparent"
                                                                                        title={
                                                                                            'Supprimer ' +
                                                                                            troncon.information +
                                                                                            ' de la rivière ' +
                                                                                            riviere.information
                                                                                        }
                                                                                        onClick={handleTronconDelete(
                                                                                            riviere.id,
                                                                                            troncon.code,
                                                                                            troncon.information,
                                                                                        )}
                                                                                    >
                                                                                        <BsTrash3
                                                                                            className="text-danger"
                                                                                            size={20}
                                                                                        />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div key={'else_riv_' + riviereCom.code}>
                                                            <div className="d-inline-flex p-2">
                                                                <button className="dropdown-item">
                                                                    {riviereCom.information +
                                                                        ' (' +
                                                                        riviereCom.code +
                                                                        ')'}
                                                                </button>
                                                                <button
                                                                    value="Delete"
                                                                    className="h-25 border-0 bg-transparent ps-2"
                                                                    title={
                                                                        'Supprimer ' +
                                                                        riviere.information +
                                                                        ' de la commune ' +
                                                                        commune.information
                                                                    }
                                                                    onClick={handleRiviereDelete(
                                                                        commune.id,
                                                                        riviere.id,
                                                                        riviere.code,
                                                                        riviere.information,
                                                                    )}
                                                                >
                                                                    <BsTrash3 className="text-danger" size={20} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })}
                                            {/* affichage des tronçons de la commune */}
                                            {commune.troncons && commune.troncons.length > 0 && (
                                                <p className="mt-3 card-text fst-italic">
                                                    Voici la liste des tronçons présents sur {commune.information} :
                                                </p>
                                            )}
                                            {commune.troncons &&
                                                commune.troncons.map((troncon) => {
                                                    return (
                                                        <div key={'troncon_' + troncon.code}>
                                                            <div className="d-inline-flex p-2">
                                                                <button className="dropdown-item">
                                                                    {troncon.information + ' (' + troncon.code + ')'}
                                                                </button>
                                                                <button
                                                                    value="Delete"
                                                                    className="h-25 border-0 bg-transparent ps-2"
                                                                    title={
                                                                        'Supprimer ' +
                                                                        troncon.information +
                                                                        ' de la commune ' +
                                                                        commune.information
                                                                    }
                                                                    onClick={handleTronconCommuneDelete(
                                                                        commune.id,
                                                                        troncon.code,
                                                                        troncon.information,
                                                                    )}
                                                                >
                                                                    <BsTrash3 className="text-danger" size={20} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
