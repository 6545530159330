import React from 'react';
import { convertHourGood } from './attendanceForm';

export default function Form402(props) {
    function handleHourChange(ent, idx) {
        return (e) => {
            props.handle402HourChange(e, props.fIdx, ent, idx);
        };
    }

    /**
     * Renvoie le nombre d'heure pré-rempli (si existant)
     * @param {string} riviere
     * @returns {number}
     */
    function getHourPresetDefaultValue(riviere) {
        let res = '';
        // props.data === {} si il s'agit d'une rivière vide crée manuellement
        if (Object.keys(props.data).length !== 0) {
            // Si l'index du formulaire 402 est plus petit que le nombre de formulaire 402 des données prédéfinie
            if (props.fIdx < props.presetData.chantiers402.length) {
                props.presetData.chantiers402[props.fIdx].list.every((loc) => {
                    // Si la rivière match avec celle attendue, on renvoie l'heure déjà remplie
                    if (loc.riviere === riviere) {
                        res = loc.nbHeure;
                        return false;
                    }
                    return true;
                });
            }
        }

        return convertHourGood(res);
    }

    return (
        <div
            id={'402-' + (props.fIdx + 1)}
            className="form-floating w-100 mt-5 mb-5 bg-light bg-opacity-50 p-3 rounded-5 shadow-lg"
        >
            <h5 className="mb-3">Formulaire code 402 n°{props.fIdx + 1}</h5>
            <div>
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-md-12 column table-responsive">
                            <table className="table table-sm table-bordered table-hover table-light table-striped align-middle">
                                <thead>
                                    <tr className="table-secondary">
                                        <th scope="col">#</th>
                                        <th scope="col">Commune</th>
                                        <th scope="col">Rivière</th>
                                        <th scope="col">Grille</th>
                                        <th scope="col">Durée (en heures)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data402.map((ent, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <th scope="row">{idx + 1}</th>
                                                <td>{ent.commune}</td>
                                                <td>{ent.riviere}</td>
                                                <td>
                                                    {ent.grille.map((grille, idx2) => {
                                                        return (
                                                            <span key={idx2}>
                                                                {grille} {idx2 !== ent.grille.length - 1 && <br />}
                                                            </span>
                                                        );
                                                    })}
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="align-middle text-center"
                                                        min={0}
                                                        defaultValue={
                                                            props.presetData && getHourPresetDefaultValue(ent.riviere)
                                                        }
                                                        onChange={handleHourChange(ent, idx)}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
