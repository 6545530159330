import React from 'react';
import NavigationBar from './navbar';

export default function Header() {
    return (
        <div id="header">
            <NavigationBar />
        </div>
    );
}
